import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import parse from "html-react-parser";
import React from "react";
import { useDetailPelatihanKontenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";

/**
 * The TabsLatihan component is used to display information related to exercises within a sub-module of the training module.
 * This component utilizes information obtained from `useDetailPelatihanKontenPelatihanContext`.
 * @component
 * @author Muhammad Farras Jibran
 */

const TabsLatihan = () => {
  const { modulPelatihan, selectModulIdx, selectSubModulIdx, paketSoal } =
    useDetailPelatihanKontenPelatihanContext().state;

  // Find the appropriate question package for the selected sub-module
  const selectedPaketSoal = paketSoal.find(
    (item) =>
      item.id ===
      modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx]
        .m_latihan.paket_soal_id,
  );

  /**
   * The LatihanItem component is used to display exercise information items.
   * @param {Object} props - Component properties.
   * @param {string} props.name - Name of the information item.
   * @param {string} props.value - Value of the information item.
   * @param {boolean} [props.isParse=false] - Indicates whether the value contains HTML that needs to be parsed.
   */
  const LatihanItem = ({ name, value, isParse = false }) => (
    <Grid
      templateColumns={{ md: "repeat(11, 1fr)", base: "repeat(3, 1fr)" }}
      mb={2}
    >
      <GridItem colSpan={{ md: 2, sm: 1 }}>
        <Text fontSize="base" fontWeight="medium" color="gray.700">
          {name}
        </Text>
      </GridItem>
      <GridItem colSpan={{ md: 4, base: 1 }}>
        {isParse ? (
          value
        ) : (
          <Text fontSize="base" color="gray.700">
            : {value}
          </Text>
        )}
      </GridItem>
    </Grid>
  );

  return (
    <Box>
      <LatihanItem
        name="Passing Grade"
        value={
          modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx]
            .m_latihan.passing_grade
        }
      />

      <LatihanItem
        name="Durasi Latihan"
        value={`${modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx].m_latihan.durasi_latihan} Menit`}
      />

      <LatihanItem
        name="Limit Pengulangan"
        value={
          modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx]
            .m_latihan.sequence ?? "-"
        }
      />

      {selectedPaketSoal && (
        <LatihanItem name="Paket Soal" value={selectedPaketSoal.judul_soal} />
      )}

      {selectedPaketSoal && (
        <LatihanItem
          name="Jumlah Soal"
          value={`${selectedPaketSoal.soal.length} Soal`}
        />
      )}

      {selectedPaketSoal && (
        <LatihanItem
          name="Kategori Soal"
          value={selectedPaketSoal.kategori_paket_soal.kategori_paket_soal}
        />
      )}

      {selectedPaketSoal && (
        <LatihanItem name="Jenis Soal" value={selectedPaketSoal.jenis_soal} />
      )}

      {selectedPaketSoal && (
        <LatihanItem
          name="Petunjuk Soal"
          value={parse(selectedPaketSoal.petunjuk_soal)}
          isParse={true}
        />
      )}
    </Box>
  );
};

export default TabsLatihan;
