/* eslint-disable eqeqeq */
import { AxiosResponse } from "axios";
import {
  Latihan,
  ModulPelatihan,
  SubModulPelatihan,
} from "../../entities/ModulPelatihan";

export class ModulPelatihanDataMapper {
  public convertModulPelatihanDataFromApi(
    result: AxiosResponse<any>,
  ): ModulPelatihan[] {
    const { data } = result.data;

    const modulPelatihan: ModulPelatihan[] = [];

    data.map((m: any) => {
      const subModulPelatihan: SubModulPelatihan[] = [];

      m.m_sub_modul_pelatihan.map((sm: any) => {
        let m_latihan;

        if (sm.ada_latihan == 1) {
          m_latihan = new Latihan(
            sm.m_latihan[0].id,
            sm.m_latihan[0].sub_modul_pelatihan_id,
            sm.m_latihan[0].materi_id,
            sm.m_latihan[0].paket_soal_id,
            Math.floor(Number(sm.m_latihan[0].durasi_latihan) / 60),
            sm.m_latihan[0].passing_grade,
            sm.m_latihan[0].sequence
          );
        } else {
          m_latihan = null;
        }

        return subModulPelatihan.push(
          new SubModulPelatihan(
            sm.id,
            sm.modul_pelatihan_id,
            sm.jenis_sub_modul,
            sm.m_materi.length > 0 ? sm.m_materi[0].materi_id : 0,
            sm.m_materi,
            sm.jenis_ujian ?? "",
            sm.judul,
            sm.paket_soal_id ?? 0,
            sm.created_at,
            sm.updated_at,
            sm.bobot_penilaian,
            sm.ada_latihan,
            m_latihan,
            sm.durasi_latihan,
            sm.passing_grade,
            sm.sequence,
            sm.limit_soal
          )
        );
      });

      return modulPelatihan.push(
        new ModulPelatihan(
          m.id,
          m.pelatihan_id,
          m.judul_modul,
          m.created_at,
          m.updated_at,
          subModulPelatihan,
          m.sequence,
        ),
      );
    });

    return modulPelatihan;
  }

  public convertDetailModulPelatihanDataFromApi(
    result: AxiosResponse<any>,
  ): ModulPelatihan {
    const { data } = result.data;

    let modulPelatihan: ModulPelatihan;

    const subModulPelatihan: SubModulPelatihan[] = [];

    data.m_sub_modul_pelatihan.map((sm: any) => {
      let m_latihan: any;

      if (sm.ada_latihan == 1) {
        m_latihan = new Latihan(
          sm.m_latihan[0].id,
          sm.m_latihan[0].sub_modul_pelatihan_id,
          sm.m_latihan[0].materi_id,
          sm.m_latihan[0].paket_soal_id,
          Math.floor(Number(sm.m_latihan[0].durasi_latihan) / 60),
          sm.m_latihan[0].passing_grade,
          sm.m_latihan[0].sequence
        );
      } else {
        m_latihan = null;
      }

      return subModulPelatihan.push(
        new SubModulPelatihan(
          sm.id,
          sm.modul_pelatihan_id,
          sm.jenis_sub_modul,
          sm.m_materi.length > 0 ? sm.m_materi[0].materi_id : 0,
          sm.m_materi,
          sm.jenis_ujian ?? "",
          sm.judul,
          sm.paket_soal_id ?? 0,
          sm.created_at,
          sm.updated_at,
          sm.bobot_penilaian,
          sm.ada_latihan,
          m_latihan,
          sm.durasi_latihan,
          sm.passing_grade,
          sm.sequence,
          sm.limit_soal
        )
      );
    });

    modulPelatihan = new ModulPelatihan(
      data.id,
      data.pelatihan_id,
      data.judul_modul,
      data.created_at,
      data.updated_at,
      subModulPelatihan,
      data.sequence,
    );

    return modulPelatihan;
  }

  public convertSubModulPelatihanDataFromApi(
    result: AxiosResponse<any>,
  ): SubModulPelatihan[] {
    const { data } = result.data;

    const subModulPelatihan: SubModulPelatihan[] = [];

    data.map((sm: any) => {
      let m_latihan: any;

      if (sm.ada_latihan == 1) {
        m_latihan = new Latihan(
          sm.m_latihan[0].id,
          sm.m_latihan[0].sub_modul_pelatihan_id,
          sm.m_latihan[0].materi_id,
          sm.m_latihan[0].paket_soal_id,
          Math.floor(Number(sm.m_latihan[0].durasi_latihan) / 60),
          sm.m_latihan[0].passing_grade,
          sm.m_latihan[0].sequence
        );
      } else {
        m_latihan = null;
      }

      return subModulPelatihan.push(
        new SubModulPelatihan(
          sm.id,
          sm.modul_pelatihan_id,
          sm.jenis_sub_modul,
          sm.m_materi.length > 0 ? sm.m_materi[0].materi_id : 0,
          sm.m_materi,
          sm.jenis_ujian ?? "",
          sm.judul,
          sm.paket_soal_id ?? 0,
          sm.created_at,
          sm.updated_at,
          sm.bobot_penilaian,
          sm.ada_latihan,
          m_latihan,
          sm.durasi_latihan,
          sm.passing_grade,
          sm.sequence,
          sm.limit_soal
        )
      );
    });

    return subModulPelatihan;
  }

  public convertDetailSubModulPelatihanDataFromApi(
    result: AxiosResponse<any>,
  ): SubModulPelatihan {
    const { data } = result.data;

    let subModulPelatihan: SubModulPelatihan;

    let m_latihan: any;

    if (data.ada_latihan == 1) {
      m_latihan = new Latihan(
        data.m_latihan[0].id,
        data.m_latihan[0].sub_modul_pelatihan_id,
        data.m_latihan[0].materi_id,
        data.m_latihan[0].paket_soal_id,
        Math.floor(Number(data.m_latihan[0].durasi_latihan) / 60),
        data.m_latihan[0].passing_grade,
        data.m_latihan[0].sequence
      );
    } else {
      m_latihan = null;
    }

    subModulPelatihan = new SubModulPelatihan(
      data.id,
      data.modul_pelatihan_id,
      data.jenis_sub_modul,
      data.m_materi.length > 0 ? data.m_materi[0].materi_id : 0,
      data.m_materi,
      data.jenis_ujian ?? "",
      data.judul,
      data.paket_soal_id ?? 0,
      data.created_at,
      data.updated_at,
      data.bobot_penilaian,
      data.ada_latihan,
      m_latihan,
      data.durasi_latihan,
      data.passing_grade,
      data.sequence,
      data.limit_soal
    );

    return subModulPelatihan;
  }
}
