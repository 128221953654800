import PrettyRating from "pretty-rating-react";
import React from "react";
import RateFullIcon from "../../assets/svg/ic_full_rate.svg";
import {
  Review,
  usePengetahuanDetailContext,
} from "../../controller/pengetahuan/detail";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";
import Pagination from "../Pagination";
import Skeleton from "react-loading-skeleton";

/**
 * Component to display individual review item.
 *
 * @param {Object} props - Component props
 * @param {Review} props.review - Review object to display
 * @returns {JSX.Element} Review item component
 */
const ItemReviewes = ({ review }: { review: Review }) => {
  const colors = {
    star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
  };

  const parseDate = (dateString) => {
    return parseISO(dateString);
  };

  const dateCreated = parseDate(review.created_at);
  return (
    <div
      className="container mt-4 p-5"
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <div className="grid grid-cols-9  gap-4">
        <div className="col-span-5 ml-1 mr-1 flex items-center">
          <img
            src={review.peserta.path_foto}
            className="rounded-full"
            style={{ width: "50px", height: "45px" }}
            alt={`img-${review.peserta.name}`}
          />
          <div className="flex flex-col ml-3">
            <p className="font-medium text-sm">{review.peserta.name}</p>
            <p className="text-xs text-gray-500">
              {format(dateCreated, "dd MMMM yyyy", { locale: id })}
            </p>
          </div>
        </div>
        <div className="col-span-4  ml-1 mr-1">
          <div className="flex grid-cols-2 items-center justify-end h-full">
            <div className="text-sm">
              {review.rating > 0 && (
                <PrettyRating value={review.rating} colors={colors.star} />
              )}
            </div>
          </div>
        </div>
        <div className="col-span-9  ml-1 mr-1">
          <p className="text-xs text-gray-500 mb-3 text-justify">
            {review.komentar}
          </p>
        </div>
      </div>
    </div>
  );
};

/**
 * Component to display the review section for a knowledge item.
 *
 * @component
 * @returns {JSX.Element} Review section component
 */
const ReviewPengetahuan: React.FC = () => {
  const { setReviewState, getReviewData } = usePengetahuanDetailContext();

  const { review, pengetahuanItem } = usePengetahuanDetailContext().state;

  const baseClass = "btn-review";
  const activeClass = "btn-review-active";

  const buttons = [
    {
      key: "all",
      label: `Semua (${pengetahuanItem?.review_count})`,
      rating: "",
    },
    { key: 5, label: "5", rating: "5" },
    { key: 4, label: "4", rating: "4" },
    { key: 3, label: "3", rating: "3" },
    { key: 2, label: "2", rating: "2" },
    { key: 1, label: "1", rating: "1" },
  ];

  const getButtonClass = (btnRating) =>
    review.sortByRating === btnRating ? activeClass : baseClass;

  const colors = {
    star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
  };

  const totalPage = Math.ceil(review.totalRow / review.perPage) ?? 0;

  const handlePageChange = (e) => {
    setReviewState("page", e.selected + 1);
  };

  useEffect(() => {
    getReviewData(window.location.pathname.split("/", 4)[3]);
  }, [review.sortByRating, review.page]);

  return (
    <div className="bg-white p-4 rounded-lg mt-4">
      {pengetahuanItem && (
        <>
          <div className="grid md:grid-cols-6 sm:grid-cols-2 gap-3 mt-4">
            <div
              className="col-span-6 md:col-span-2 sm:grid-cols-2"
              style={{
                backgroundColor: "rgba(255, 194, 113, 0.1)",
                borderRadius: "10px",
              }}
            >
              <div
                className="text-center font-semibold mt-4"
                style={{ fontSize: "50px" }}
              >
                {pengetahuanItem?.rata_rata_rating}
              </div>
              <div className="text-center text-lg">
                <PrettyRating
                  value={pengetahuanItem?.rata_rata_rating ?? 0}
                  colors={colors.star}
                />
              </div>
              <p className="text-center mb-5 mt-1" style={{ color: "#7A8990" }}>
                {review.listData.length > 0 ? review.totalRow : 0} Reviews
              </p>
            </div>
            <div
              className="col-span-6 md:col-span-4 sm:grid-cols-2  ml-1 mr"
              style={{
                backgroundColor: "rgba(255, 194, 113, 0.1)",
                borderRadius: "10px",
              }}
            >
              <div className="py-4 px-2">
                <div className="grid grid-cols-9">
                  <div
                    className="col-span-9 md:col-span-2"
                    style={{ color: "#7A8990" }}
                  >
                    Excellent
                  </div>
                  <div className="col-span-7 md:col-span-6">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                      <div
                        className="bg-yellow-400 h-1.5 rounded-full"
                        style={{
                          width: `${
                            pengetahuanItem?.count_review_excellent ?? 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-span-2 md:col-span-1 ml-2"
                    style={{ color: "#7A8990" }}
                  >
                    {Math.round(pengetahuanItem?.count_review_excellent ?? 0)}%
                  </div>
                </div>
                <div className="grid grid-cols-9 mt-2">
                  <div
                    className="col-span-9 md:col-span-2"
                    style={{ color: "#7A8990" }}
                  >
                    Very Good
                  </div>
                  <div className="col-span-7 md:col-span-6">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                      <div
                        className="bg-yellow-400 h-1.5 rounded-full"
                        style={{
                          width: `${
                            pengetahuanItem?.count_review_verry_good ?? 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-span-2 md:col-span-1 ml-2"
                    style={{ color: "#7A8990" }}
                  >
                    {Math.round(pengetahuanItem?.count_review_verry_good ?? 0)}%
                  </div>
                </div>
                <div className="grid grid-cols-9 mt-2">
                  <div
                    className="col-span-9 md:col-span-2"
                    style={{ color: "#7A8990" }}
                  >
                    Average
                  </div>
                  <div className="col-span-7 md:col-span-6">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                      <div
                        className="bg-yellow-400 h-1.5 rounded-full"
                        style={{
                          width: `${
                            pengetahuanItem?.count_review_average ?? 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-span-2 md:col-span-1 ml-2"
                    style={{ color: "#7A8990" }}
                  >
                    {Math.round(pengetahuanItem?.count_review_average ?? 0)}%
                  </div>
                </div>
                <div className="grid grid-cols-9 mt-2">
                  <div
                    className="col-span-9 md:col-span-2"
                    style={{ color: "#7A8990" }}
                  >
                    Poor
                  </div>
                  <div className="col-span-7 md:col-span-6">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                      <div
                        className="bg-yellow-400 h-1.5 rounded-full"
                        style={{
                          width: `${pengetahuanItem?.count_review_poor ?? 0}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-span-2 md:col-span-1 ml-2"
                    style={{ color: "#7A8990" }}
                  >
                    {Math.round(pengetahuanItem?.count_review_poor ?? 0)}%
                  </div>
                </div>
                <div className="grid grid-cols-9 mt-2">
                  <div
                    className="col-span-9 md:col-span-2"
                    style={{ color: "#7A8990" }}
                  >
                    Terrible
                  </div>
                  <div className="col-span-7 md:col-span-6">
                    <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                      <div
                        className="bg-yellow-400 h-1.5 rounded-full"
                        style={{
                          width: `${
                            pengetahuanItem?.count_review_terrible ?? 0
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="col-span-2 md:col-span-1 ml-2"
                    style={{ color: "#7A8990" }}
                  >
                    {Math.round(pengetahuanItem?.count_review_terrible ?? 0)}%
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap">
            {buttons.map(({ key, label, rating: btnRating }) => (
              <button
                key={key}
                className={`py-2 px-4 ${getButtonClass(btnRating)} mr-2 mt-2`}
                onClick={() => {
                  setReviewState("sortByRating", btnRating);
                  setReviewState("page", 1);
                }}
              >
                {key !== "all" && <RateFullIcon className="mr-1" />}
                {label}
              </button>
            ))}
          </div>
        </>
      )}

      {review.isLoadingData ? (
        <div
          className="container mt-4 p-5"
          style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
        >
          <div className="grid md:grid-cols-9 sm:grid-cols-3 gap-4">
            <div className="col-span-1 md:col-span-1 sm:grid-cols-1 ml-1 mr-1">
              <Skeleton width={50} height={50} />
            </div>
            <div className="col-span-8 md:col-span-8 sm:grid-cols-1 ml-1 mr-1">
              <p className="font-medium text-base">
                <Skeleton count={2} />
              </p>
            </div>
          </div>
          <p className="mt-4">
            <Skeleton count={2} />
          </p>
        </div>
      ) : (
        <>
          {review?.listData.length > 0 ? (
            review?.listData.map((v: Review, key) => (
              <ItemReviewes review={v} key={key} />
            ))
          ) : (
            <p className="text-center text-md mt-8">Tidak ada review!</p>
          )}
        </>
      )}

      {totalPage > 1 && (
        <div className="mt-4 flex justify-center">
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={review.page}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewPengetahuan;
