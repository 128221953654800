import { DependencyContainer } from "tsyringe";
import { AuthPresenter } from "../../data/presenters/AuthPresenter";
import { ExamPresenter } from "../../data/presenters/ExamPresenter";
import { FaqPresenter } from "../../data/presenters/FaqPresenter";
import { FinalProjectPresenter } from "../../data/presenters/FinalProjectPresenter";
import { JenisPelatihanPresenter } from "../../data/presenters/JenisPelatihanPresenter";
import { KabupatenPresenter } from "../../data/presenters/KabupatenPresenter";
import { KategoriPaketSoalPresenter } from "../../data/presenters/KategoriPaketSoalPresenter";
import { KecamatanPresenter } from "../../data/presenters/KecamatanPresenter";
import { KelurahanPresenter } from "../../data/presenters/KelurahanPresenter";
import { LandingDataPresenter } from "../../data/presenters/LandingDataPresenter";
import { LogLatihanPresenter } from "../../data/presenters/LogLatihanPresenter";
import { MateriPresenter } from "../../data/presenters/MateriPresenter";
import { MenuJenisPelatihanPresenter } from "../../data/presenters/MenuJenisPelatihanPresenter";
import { ModulPelatihanPresenter } from "../../data/presenters/ModulPelatihanPresenter";
import { PaketSoalPresenter } from "../../data/presenters/PaketSoalPresenter";
import { PelatihanPresenter } from "../../data/presenters/PelatihanPresenter";
import { PelatihanSayaPresenter } from "../../data/presenters/PelatihanSayaPresenter";
import { ProvinsiPresenter } from "../../data/presenters/ProvinsiPresenter";
import { QuizPresenter } from "../../data/presenters/QuizPresenter";
import { ReviewMateriPresenter } from "../../data/presenters/ReviewMateriPresenter";
import { ReviewPelatihanPresenter } from "../../data/presenters/ReviewPelatihanPresenter";
import { SettingPenilaianPresenter } from "../../data/presenters/SettingPenilaianPresenter";
import { SliderPresenter } from "../../data/presenters/SliderPresenter";
import { SoalPresenter } from "../../data/presenters/SoalPresenter";
import { SproutVideoPresenter } from "../../data/presenters/SproutVideoPresenter";
import { UsersPresenter } from "../../data/presenters/UsersPresenter";
import { AuthApiRepository } from "../repository/api/AuthApiRepository";
import { ExamApiRepository } from "../repository/api/ExamApiRepository";
import { FaqRepository } from "../repository/api/FaqRespository";
import { FinalProjectApiRepository } from "../repository/api/FinalProjectApiRepository";
import { JenisPelatihanApiRepository } from "../repository/api/JenisPelatihanApiRepository";
import { KabupatenApiRepository } from "../repository/api/KabupatenApiRepository";
import { KategoriPaketSoalApiRepository } from "../repository/api/KategoriPaketSoalApiRepository";
import { KecamatanApiRepository } from "../repository/api/KecamatanApiRepository";
import { KelurahanApiRepository } from "../repository/api/KelurahanApiRepository";
import { LandingDataApiRepository } from "../repository/api/LandingDataRepository";
import { LogLatihanDataApiRepository } from "../repository/api/LogLatihanApiRepository";
import { MateriApiRepository } from "../repository/api/MateriApiRepository";
import { MenuJenisPelatihanApiRepository } from "../repository/api/MenuJenisPelatihanApiRepository";
import { ModulPelatihanApiRepository } from "../repository/api/ModulPelatihanApiRepository";
import { PaketSoalApiRepository } from "../repository/api/PaketSoalApiRepository";
import { PelatihanApiRepository } from "../repository/api/PelatihanApiRepository";
import { PelatihanSayaApiRepository } from "../repository/api/PelatihanSayaApiRepository";
import { ProvinsiApiRepository } from "../repository/api/ProvinsiApiRepository";
import { QuizApiRepository } from "../repository/api/QuizApiRepository";
import { ReviewMateriApiRepository } from "../repository/api/ReviewMateriApiRepository";
import { ReviewPelatihanApiRepository } from "../repository/api/ReviewPelatihanApiRepository";
import { SettingPenilaianApiRepository } from "../repository/api/SettingPenilaianApiRepository";
import { SliderApiRepository } from "../repository/api/SliderApiRepository";
import { SoalApiRepository } from "../repository/api/SoalApiRepository";
import { SproutVideoApiRepository } from "../repository/api/SproutVideoApiRepository";
import { UsersApiRepository } from "../repository/api/UsersApiRepository";
import { HelpdeskBantuanApiRepository } from "../repository/api/HelpdeskBantuanApiRepository";
import { HelpdeskBantuanPresenter } from "../../data/presenters/HelpdeskBantuanPresenter";
import { HelpDeskBantuanPublicPresenter } from "../../data/presenters/HelpDeskBantuanPublicPresenter";
import { HelpDeskBantuanPublicApiRepository } from "../repository/api/HelpDeskBantuanPublicApiRepository";
import { UjianPresenter } from "../../data/presenters/UjianPresenter";
import { UjianApiRepository } from "../repository/api/UjianApiRepository";
import { DashboardPresenter } from "../../data/presenters/DashboardPresenter";
import { DashboardApiRepository } from "../repository/api/DashboardApiRepository";
import { JawabanSoalPresenter } from "../../data/presenters/JawabanSoalPresenter";
import { JawabanSoalApiRepository } from "../repository/api/JawabanSoalApiRepository";
import { PathMateriPresenter } from "../../data/presenters/PathMateriPresenter";
import { PathMateriApiRepository } from "../repository/api/PathMateriApiRepository";
import { DashboardRekapitulasiPresenter } from "../../data/presenters/DashboardRekapitulasiPresenter";
import { DashboardRekapitulasiApiRepository } from "../repository/api/DashboardRekapitulasiApiRepository";
import { SertifikatTTEPresenter } from "../../data/presenters/SertifikatTTEPresenter";
import { SertifikatTTERepository } from "../repository/api/SertifikatTTERepository";
import { YoutubePresenter } from "../../data/presenters/YoutubePresenter";
import { YoutubeApiRepository } from "../repository/api/YoutubeApiRepository";
import { SimdiklatPresenter } from "../../data/presenters/SimdiklatPresenter";
import { SimdiklatApiRepository } from "../repository/api/SimdiklatApiRepository";
import { KategoriPengetahuanPresenter } from "../../data/presenters/KategoriPengetahuanPresenter";
import { KategoriPengetahuanApiRepository } from "../repository/api/KategoriPengetahuanApiRepository";

export class PresenterModule {
  public static init(container: DependencyContainer) {
    /**
     * [register description]
     *
     * @param   {[type]}  AuthPresenter  [AuthPresenter description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<AuthPresenter>(AuthPresenter, {
      useFactory: (d) => {
        return new AuthPresenter(d.resolve(AuthApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PaketSoalPresenter  [PaketSoalPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<PaketSoalPresenter>(PaketSoalPresenter, {
      useFactory: (d) => {
        return new PaketSoalPresenter(d.resolve(PaketSoalApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SoalPresenter  [SoalPresenter description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<SoalPresenter>(SoalPresenter, {
      useFactory: (d) => {
        return new SoalPresenter(d.resolve(SoalApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KategoriPaketSoalPresenter  [KategoriPaketSoalPresenter description]
     *
     * @return  {[type]}                              [return description]
     */
    container.register<KategoriPaketSoalPresenter>(KategoriPaketSoalPresenter, {
      useFactory: (d) => {
        return new KategoriPaketSoalPresenter(
          d.resolve(KategoriPaketSoalApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KategoriPengetahuanPresenter  [KategoriPengetahuanPresenter description]
     *
     * @return  {[type]}                              [return description]
     */
    container.register<KategoriPengetahuanPresenter>(KategoriPengetahuanPresenter, {
      useFactory: (d) => {
        return new KategoriPengetahuanPresenter(
          d.resolve(KategoriPengetahuanApiRepository)
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  MateriPresenter  [MateriPresenter description]
     *
     * @return  {[type]}                   [return description]
     */
    container.register<MateriPresenter>(MateriPresenter, {
      useFactory: (d) => {
        return new MateriPresenter(d.resolve(MateriApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  UsersPresenter  [UsersPresenter description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<UsersPresenter>(UsersPresenter, {
      useFactory: (d) => {
        return new UsersPresenter(d.resolve(UsersApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanPresenter  [PelatihanPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<PelatihanPresenter>(PelatihanPresenter, {
      useFactory: (d) => {
        return new PelatihanPresenter(d.resolve(PelatihanApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FaqPresenter  [FaqPresenter description]
     *
     * @return  {[type]}                [return description]
     */
    container.register<FaqPresenter>(FaqPresenter, {
      useFactory: (d) => {
        return new FaqPresenter(d.resolve(FaqRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ProvinsiPresenter  [ProvinsiPresenter description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<ProvinsiPresenter>(ProvinsiPresenter, {
      useFactory: (d) => {
        return new ProvinsiPresenter(d.resolve(ProvinsiApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KabupatenPresenter  [KabupatenPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<KabupatenPresenter>(KabupatenPresenter, {
      useFactory: (d) => {
        return new KabupatenPresenter(d.resolve(KabupatenApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  JawabanSoalPresenter  [JawabanSoalPresenter description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<JawabanSoalPresenter>(JawabanSoalPresenter, {
      useFactory: (d) => {
        return new JawabanSoalPresenter(d.resolve(JawabanSoalApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  UjianPresenter  [UjianPresenter description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<UjianPresenter>(UjianPresenter, {
      useFactory: (d) => {
        return new UjianPresenter(d.resolve(UjianApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardPresenter  [DashboardPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<DashboardPresenter>(DashboardPresenter, {
      useFactory: (d) => {
        return new DashboardPresenter(d.resolve(DashboardApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KecamatanPresenter  [KecamatanPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<KecamatanPresenter>(KecamatanPresenter, {
      useFactory: (d) => {
        return new KecamatanPresenter(d.resolve(KecamatanApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ReviewPelatihanPresenter  [ReviewPelatihanPresenter description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<ReviewPelatihanPresenter>(ReviewPelatihanPresenter, {
      useFactory: (d) => {
        return new ReviewPelatihanPresenter(
          d.resolve(ReviewPelatihanApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  HelpDeskBantuanPublicPresenter  [HelpDeskBantuanPublicPresenter description]
     *
     * @return  {[type]}                                  [return description]
     */
    container.register<HelpDeskBantuanPublicPresenter>(
      HelpDeskBantuanPublicPresenter,
      {
        useFactory: (d) => {
          return new HelpDeskBantuanPublicPresenter(
            d.resolve(HelpDeskBantuanPublicApiRepository),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  SproutVideoPresenter  [SproutVideoPresenter description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<SproutVideoPresenter>(SproutVideoPresenter, {
      useFactory: (d) => {
        return new SproutVideoPresenter(d.resolve(SproutVideoApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KelurahanPresenter  [KelurahanPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<KelurahanPresenter>(KelurahanPresenter, {
      useFactory: (d) => {
        return new KelurahanPresenter(d.resolve(KelurahanApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ModulPelatihanPresenter  [ModulPelatihanPresenter description]
     *
     * @return  {[type]}                           [return description]
     */
    container.register<ModulPelatihanPresenter>(ModulPelatihanPresenter, {
      useFactory: (d) => {
        return new ModulPelatihanPresenter(
          d.resolve(ModulPelatihanApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  MenuJenisPelatihanPresenter  [MenuJenisPelatihanPresenter description]
     *
     * @return  {[type]}                               [return description]
     */
    container.register<MenuJenisPelatihanPresenter>(
      MenuJenisPelatihanPresenter,
      {
        useFactory: (d) => {
          return new MenuJenisPelatihanPresenter(
            d.resolve(MenuJenisPelatihanApiRepository),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  JenisPelatihanPresenter  [JenisPelatihanPresenter description]
     *
     * @return  {[type]}                           [return description]
     */
    container.register<JenisPelatihanPresenter>(JenisPelatihanPresenter, {
      useFactory: (d) => {
        return new JenisPelatihanPresenter(
          d.resolve(JenisPelatihanApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ExamPresenter  [ExamPresenter description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<ExamPresenter>(ExamPresenter, {
      useFactory: (d) => {
        return new ExamPresenter(d.resolve(ExamApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FinalProjectPresenter  [FinalProjectPresenter description]
     *
     * @return  {[type]}                         [return description]
     */
    container.register<FinalProjectPresenter>(FinalProjectPresenter, {
      useFactory: (d) => {
        return new FinalProjectPresenter(d.resolve(FinalProjectApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  LogLatihanPresenter  [LogLatihanPresenter description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<LogLatihanPresenter>(LogLatihanPresenter, {
      useFactory: (d) => {
        return new LogLatihanPresenter(d.resolve(LogLatihanDataApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanSayaPresenter  [PelatihanSayaPresenter description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<PelatihanSayaPresenter>(PelatihanSayaPresenter, {
      useFactory: (d) => {
        return new PelatihanSayaPresenter(
          d.resolve(PelatihanSayaApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  LandingDataPresenter  [LandingDataPresenter description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<LandingDataPresenter>(LandingDataPresenter, {
      useFactory: (d) => {
        return new LandingDataPresenter(d.resolve(LandingDataApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SettingPenilaianPresenter  [SettingPenilaianPresenter description]
     *
     * @return  {[type]}                             [return description]
     */
    container.register<SettingPenilaianPresenter>(SettingPenilaianPresenter, {
      useFactory: (d) => {
        return new SettingPenilaianPresenter(
          d.resolve(SettingPenilaianApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  QuizPresenter  [QuizPresenter description]
     *
     * @return  {[type]}                 [return description]
     */
    container.register<QuizPresenter>(QuizPresenter, {
      useFactory: (d) => {
        return new QuizPresenter(d.resolve(QuizApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ReviewMateriPresenter  [ReviewMateriPresenter description]
     *
     * @return  {[type]}                         [return description]
     */
    container.register<ReviewMateriPresenter>(ReviewMateriPresenter, {
      useFactory: (d) => {
        return new ReviewMateriPresenter(d.resolve(ReviewMateriApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SliderPresenter  [SliderPresenter description]
     *
     * @return  {[type]}                   [return description]
     */
    container.register<SliderPresenter>(SliderPresenter, {
      useFactory: (d) => {
        return new SliderPresenter(d.resolve(SliderApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  HelpdeskBantuanPresenter  [HelpdeskBantuanPresenter description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<HelpdeskBantuanPresenter>(HelpdeskBantuanPresenter, {
      useFactory: (d) => {
        return new HelpdeskBantuanPresenter(
          d.resolve(HelpdeskBantuanApiRepository),
        );
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PathMateriPresenter  [PathMateriPresenter description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<PathMateriPresenter>(PathMateriPresenter, {
      useFactory: (d) => {
        return new PathMateriPresenter(d.resolve(PathMateriApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardRekapitulasiPresenter  [DashboardRekapitulasiPresenter description]
     *
     * @return  {[type]}                                  [return description]
     */
    container.register<DashboardRekapitulasiPresenter>(
      DashboardRekapitulasiPresenter,
      {
        useFactory: (d) => {
          return new DashboardRekapitulasiPresenter(
            d.resolve(DashboardRekapitulasiApiRepository),
          );
        },
      },
    );

    /**
     * [register description]
     *
     * @param   {[type]}  SertifikatTTEPresenter  [SertifikatTTEPresenter description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<SertifikatTTEPresenter>(SertifikatTTEPresenter, {
      useFactory: (d) => {
        return new SertifikatTTEPresenter(d.resolve(SertifikatTTERepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  YoutubePresenter  [YoutubePresenter description]
     *
     * @return  {[type]}                    [return description]
     */
    container.register<YoutubePresenter>(YoutubePresenter, {
      useFactory: (d) => {
        return new YoutubePresenter(d.resolve(YoutubeApiRepository));
      },
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SimdiklatPresenter  [SimdiklatPresenter description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<SimdiklatPresenter>(SimdiklatPresenter, {
      useFactory: (d) => {
        return new SimdiklatPresenter(d.resolve(SimdiklatApiRepository));
      },
    });
  }
}
