import {
  Box,
  Divider,
  Icon,
  Image,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { BsStack } from "react-icons/bs";
import { usePengetahuanContext } from "../../controller/pengetahuan/index";

type CategoryPengetahuanProps = {
  page: string;
  type: "async" | "sync";
};

const CategoryItem = ({
  label,
  icon,
  isSelected,
  onClick,
}: {
  label: string;
  icon: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <ListItem
    onClick={onClick}
    cursor="pointer"
    bg={isSelected ? "green.100" : "transparent"}
    _hover={{ bg: "green.100", color: "green.800" }}
  >
    <Stack direction="row" alignItems="center" px={2}>
      {icon}
      <Text color={isSelected ? "green.800" : "gray.500"}>{label}</Text>
    </Stack>
  </ListItem>
);

/**
 * CategoryPengetahuan is a component that displays a list of knowledge categories
 * and allows users to select a category. When a category is selected, it updates
 * the application's state with the selected category ID and resets the page number
 * for the given page to 1. The component uses the context from usePengetahuanContext
 * to access and set the state, including the list of categories and the currently
 * selected category ID.
 *
 * @param {Object} props - Properties passed to the component.
 * @param {string} props.page - The current page identifier, used to reset the page
 * number when a category is selected.
 *
 * @component
 * @returns {JSX.Element} A Box component containing a list of categories.
 */

const CategoryPengetahuan = ({ page, type }: CategoryPengetahuanProps) => {
  const { setState, state } = usePengetahuanContext();

  const { listKategoriPengetahuan, kategori_pengetahuan_id } = state;

  const handleSelectCategory = useCallback(
    (categoryId: string) => {
      setState((prev) => ({
        ...prev,
        [page]: 1,
        kategori_pengetahuan_id: categoryId,
      }));
    },
    [setState]
  );

  return (
    <Box bg="white" rounded="lg" py={2}>
      <Text fontSize="md" fontWeight="semibold" textAlign="center">
        Kategori Pengetahuan
      </Text>
      <Divider my={2} />
      <List spacing={2}>
        <CategoryItem
          label="Semua"
          icon={<Icon as={BsStack} w="1.5rem" h="1.2rem" />}
          isSelected={kategori_pengetahuan_id === ""}
          onClick={() => handleSelectCategory("")}
        />
        {listKategoriPengetahuan.map((item) => (
          <CategoryItem
            key={item.value}
            label={`${item.label} (${type === "sync" ? item.totalSync : item.totalAsync})`}
            icon={
              <Image
                src={item.icon}
                alt={`Icon ${item.label}`}
                boxSize="1.5rem"
              />
            }
            isSelected={kategori_pengetahuan_id === item.value?.toString()}
            onClick={() => handleSelectCategory(item.value?.toString())}
          />
        ))}
      </List>
    </Box>
  );
};

export default CategoryPengetahuan;
