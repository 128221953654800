import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";

interface ConfirmationDialogProps {
  pengetahuanId: string;
}

/**
 * A modal dialog that asks for confirmation when a user wants to register for a knowledge item
 * or follow a speaker. The modal has a confirmation message and two buttons, one to cancel
 * and one to confirm. When the user clicks the confirm button, the
 * handleChangeStatusPengetahuan function is called with the argument pengetahuanId and the
 * statusPost argument to indicate that the user wants to register or follow the speaker.
 * @param {ConfirmationDialogProps} props The properties of the modal dialog.
 * @returns {JSX.Element} The JSX element representing the modal dialog.
 * @author Bagas Prasetyadi
 */
const ModalConfirmationPengetahuan: React.FC<ConfirmationDialogProps> = ({
  pengetahuanId,
}) => {
  const { setPengetahuanDetailState: setState, handleChangeStatusPengetahuan } =
    usePengetahuanDetailContext();
  const {
    isOpenModalConfirmationPengetahuan,
    loadingChangeStatusPengetahuan,
    pengetahuanItem,
    statusPost,
  } = usePengetahuanDetailContext().state;

  const cancelRef = useRef(null);

  const onClose = () => {
    setState("isOpenModalConfirmationPengetahuan", false);
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpenModalConfirmationPengetahuan}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          Konfirmasi{" "}
          {pengetahuanItem?.jenis_pengetahuan === "synchronized"
            ? "Pendaftaran"
            : "Mengikuti"}
        </AlertDialogHeader>
        <AlertDialogBody>
          Apakah kamu yakin untuk{" "}
          {pengetahuanItem?.jenis_pengetahuan === "synchronized"
            ? "mendaftar"
            : "mengikuti"}{" "}
          pengetahuan ini?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Tidak
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            isLoading={loadingChangeStatusPengetahuan}
            onClick={() =>
              handleChangeStatusPengetahuan(pengetahuanId, statusPost)
            }
          >
            Ya
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ModalConfirmationPengetahuan;
