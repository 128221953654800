/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { ModulPelatihanPresenter } from "../../../../../../data/presenters/ModulPelatihanPresenter";
import { toast } from "react-toastify";
import { ModulPelatihan } from "../../../../../../entities/ModulPelatihan";
import { PelatihanPresenter } from "../../../../../../data/presenters/PelatihanPresenter";
import axiosInstance from "../../../../../api/axiosInstance";

interface PenilaianSubModul {
  id: number;
  bobotNilai: number;
}
interface IState {
  isLoading: boolean;
  modulPelatihan: ModulPelatihan[];
  bobotNilai: number;
  showDialog: boolean;
  loadingFinishSave: boolean;
  statusPublish: boolean;
  penilaianSubModul: PenilaianSubModul[];
  totalBobotNilai: number;
  jenisPenilaianId: number;
  pelatihanId: number;
}

interface InitialState {
  state: IState;
  bobotPenilaianSubModul: Function;
  getModulPelatihan: Function;
  setShowDialog: Function;
  savePelatihan: Function;
  setStatusPublish: Function;
  setPenilaianSubModul: Function;
  setArrPenilaianSubModul: Function;
  setTotalBobotNilai: Function;
  handleFinish: Function;
  getDetailPelatihan: Function;
}

const initialState = {
  state: {
    isLoading: false,
    modulPelatihan: [],
    bobotNilai: 0,
    showDialog: false,
    loadingFinishSave: false,
    statusPublish: false,
    penilaianSubModul: [],
    totalBobotNilai: 0,
    jenisPenilaianId: 0,
    pelatihanId: 0,
  },
  bobotPenilaianSubModul: () => {},
  setModulPelatihanId: () => {},
  getModulPelatihan: () => {},
  setShowDialog: () => {},
  savePelatihan: () => {},
  setStatusPublish: () => {},
  setPenilaianSubModul: () => {},
  setArrPenilaianSubModul: () => {},
  setTotalBobotNilai: () => {},
  handleFinish: () => {},
  getDetailPelatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PenilaianManajemenPelatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    modulPelatihan: [],
    bobotNilai: 0,
    showDialog: false,
    loadingFinishSave: false,
    statusPublish: false,
    penilaianSubModul: [],
    totalBobotNilai: 0,
    jenisPenilaianId: 0,
    pelatihanId: 0,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setBobotNilai = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      bobotNilai: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setLoadingFinishSave = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      loadingFinishSave: value,
    }));
  };

  const setStatusPublish = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      statusPublish: value,
    }));
  };

  const setTotalBobotNilai = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      totalBobotNilai: value,
    }));
  };

  const handleFinish = () => {
    if (state.totalBobotNilai < 0) {
      toast.error("Bobot penilaian tidak dapat kurang dari 0.");
      return;
    }
    if (state.totalBobotNilai > 100) {
      toast.error("Bobot penilaian tidak dapat melebihi 100.");
      return;
    }
    setShowDialog(true);
  };

  const setPenilaianSubModul = (value) => {
    const existingIndex = state.penilaianSubModul.findIndex(
      (item) => item.id === value.id,
    );

    if (existingIndex !== -1) {
      const updatedPenilaianSubModul = state.penilaianSubModul.map(
        (item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              bobotNilai: value.bobotNilai,
            };
          }
          return item;
        },
      );

      setState((prevstate) => ({
        ...prevstate,
        penilaianSubModul: updatedPenilaianSubModul,
      }));
    } else {
      setState((prevstate) => ({
        ...prevstate,
        penilaianSubModul: [...state.penilaianSubModul, value],
      }));
    }
  };

  const setArrPenilaianSubModul = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      penilaianSubModul: value,
    }));
  };

  const bobotPenilaianSubModul = async (id: number, bobotNilai: number) => {
    setLoading(true);

    setBobotNilai(bobotNilai);

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter,
      );

      const store =
        await modulPelatihanPresenter.bobotPenilaianSubModulPelatihan(
          id,
          bobotNilai,
        );

      const data = store;

      if (data.data.code == 200) {
        toast.success("Berhasil Merubah Data Bobot Nilai.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
      } else {
        toast.error("Ada Kesalahan Saat Tambah Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoading(false);
    } catch (error: any) {
      console.log("error modulPelatihan:", error);
      toast.error("Ada Kesalahan Saat Merubah Bobot Nilai!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const getDetailPelatihan = async (uuid: string) => {
    try {
      const { data } = await axiosInstance.get("pelatihan/" + uuid);

      setState((prevstate) => ({
        ...prevstate,
        pelatihanId: data?.data?.id,
        statusPublish: data?.data?.status_publish === 1 ? true : false,
        jenisPenilaianId: data?.data?.jenis_penilaian_id,
      }));

      return data.data;
    } catch (error) {
      throw error;
    }
  };

  const getModulPelatihan = async (id: number) => {
    try {
      const modulPelatihan = container.resolve(ModulPelatihanPresenter);

      const get = await modulPelatihan.getAllModulPelatihan(
        1,
        10000000,
        "",
        id,
      );

      const data = get;



      setState((prevstate) => ({
        ...prevstate,
        modulPelatihan: data,
      }));

      if (data) {
        const arrPenilaianSubModul = data.map((item) => {
          return item.subModulPelatihan.map((sm) => {
            if ((sm.ada_latihan == 0 && sm.jenis_ujian == "") || state.jenisPenilaianId === 2) {
              return { id: sm.id, bobotNilai: 0 };
            }
            return { id: sm.id, bobotNilai: sm.bobot_penilaian };
          });
        });

        setArrPenilaianSubModul(arrPenilaianSubModul.flat());
      }
    } catch (error: any) {
      console.log("error get Modul Pelatihan:", error);
    }
  };

  const postBobotPenilaian = async (dataArray) => {
    try {
      const requests = dataArray.map(async (item) => {
        const modulPelatihanPresenter = container.resolve(
          ModulPelatihanPresenter,
        );

        if (item.bobotNilai !== null) {
          const store =
            await modulPelatihanPresenter.bobotPenilaianSubModulPelatihan(
              item.id,
              item.bobotNilai,
            );

          const data = store;
          return data;
        }
      });
      const results = await Promise.all(requests);

      return results;
    } catch (error) {
      throw error;
    }
  };

  const savePelatihan = async (id: number) => {
    setLoadingFinishSave(true);

    try {
      await postBobotPenilaian(state.penilaianSubModul);

      const pelatihanPresenter = container.resolve(PelatihanPresenter);

      const store = await pelatihanPresenter.updateStatusPublishPelatihan(
        id,
        state.statusPublish,
      );
      const data = store;

      if (data.data.code == 200) {
        toast.success("Berhasil Menyimpan Pelatihan", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        window.location.href = "/admin/manajemen_pelatihan";
      } else {
        toast.error("Ada Kesalahan Saat Menyimpan Pelatihan!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("error save pelatihan:", error);
      toast.error("Ada Kesalahan Saat Menyimpan Pelatihan!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingFinishSave(false);
    }
  };

  return (
    <PenilaianManajemenPelatihanProvider
      value={{
        state,
        bobotPenilaianSubModul,
        getModulPelatihan,
        setShowDialog,
        savePelatihan,
        setStatusPublish,
        setPenilaianSubModul,
        setArrPenilaianSubModul,
        setTotalBobotNilai,
        handleFinish,
        getDetailPelatihan,
      }}
    >
      {children}
    </PenilaianManajemenPelatihanProvider>
  );
};

export const usePenilaianManajemenPelatihanContext = () =>
  React.useContext(Context);
// eslint-disable-next-line
export default {
  usePenilaianManajemenPelatihanContext,
  Provider,
};
