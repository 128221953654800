import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillPlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import CardItem from "../../../../components/Admin/Card/CardItem";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import BoxUploadFile from "../../../../components/Admin/UploadFile/BoxUploadFile";
import Pagination from "../../../../components/Pagination";
import InfografisController, {
  useInfografisContext,
} from "../../../../controller/admin/landing_page/infografis";

/**
 * Infografis is a component that allows users to manage infographics.
 * It provides a form to create new infographics and displays a list of existing infographics.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Infografis = () => {
  const {
    setInfografisState,
    fetchData,
    postData,
    handleDelete,
    handleEdit,
    clearState,
  } = useInfografisContext();
  const state = useInfografisContext().state;

  const {
    publish,
    image,
    mode,
    description,
    title,
    loadingData,
    loadingAction,
    infografisData: data,
    pagination,
    perPage,
    page,
  } = state;

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const handlePageChange = (e) => {
    setInfografisState("page", e.selected + 1);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="var(--grey-100, #EDF2F7)"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            {mode !== "" && (
              <IconButton
                aria-label="Previous Page"
                icon={<AiOutlineArrowLeft />}
                fontSize="md"
                mr={2}
                onClick={() => setInfografisState("mode", "")}
              />
            )}
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              {mode === "create" ? "Tambah" : mode === "edit" ? "Ubah" : ""}{" "}
              Infografis
            </Heading>
          </Flex>
          {(mode === "create" || mode === "edit") && publish ? (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Publish"
              onClick={() => postData(mode, "")}
            >
              Publish
            </Button>
          ) : (mode === "create" || mode === "edit") && !publish ? (
            <Button
              color="white"
              bg="#3C4952"
              _hover={{ bg: "#3C4952" }}
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Simpan Draft"
              onClick={() => postData(mode, "")}
            >
              Simpan Draft
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              onClick={() => {
                clearState();
                setInfografisState("mode", "create");
              }}
            >
              Tambah Baru
            </Button>
          )}
        </Flex>
      </Box>
      {mode === "create" || mode === "edit" ? (
        <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* judul */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={title}
                onChange={(e) => setInfografisState("title", e.target.value)}
                bg="var(--strokeluar, #F6F5F8)"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            {/*  Gambar */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Cover Thumbnail
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setInfografisState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Deskripsi
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Textarea
                defaultValue={description}
                onChange={(e) =>
                  setInfografisState("description", e.target.value)
                }
                h="100px"
                bg="var(--strokeluar, #F6F5F8)"
                _focus={{
                  ring: "1",
                  ringColor: "blue.500",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            {/* Publikasi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Publikasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Switch
                defaultChecked={publish}
                colorScheme="blue"
                mr={2}
                onChange={(e) =>
                  setInfografisState("publish", e.target.checked)
                }
              />
              <Text fontWeight="semibold" fontSize="sm">
                {publish ? "Publikasi" : "Draft"}
              </Text>
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={4} m={4}>
          {loadingData ? (
            <>
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
            </>
          ) : (
            data.map((item) => (
              <GridItem colSpan={{ base: 3, md: 1 }} key={item.id}>
                <CardItem
                  item={item}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  image={item.image}
                  module="infografis"
                />
              </GridItem>
            ))
          )}
        </Grid>
      )}
      {pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={2} mb={4} mr={3}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </SidebarWithHeader>
  );
};

const InfografisMain: React.FC = () => {
  return (
    <InfografisController.Provider>
      <Infografis />
    </InfografisController.Provider>
  );
};

export default InfografisMain;
