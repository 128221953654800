import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import Skeleton from "react-loading-skeleton";
import { Box, Text, Link, Container } from "@chakra-ui/react";
import { usePengetahuanContext } from "../../controller/pengetahuan/index";
import CardItem from "../global/Card/CardItem";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

interface KnowledgeCenterProps {
  title: string;
  type: string;
  navigatePath: string;
}

interface KnowledgeCarouselProps {
  listData: any[];
}

const KnowledgeCarousel: React.FC<KnowledgeCarouselProps> = ({ listData }) => {
  if (listData.length === 0) {
    return (
      <Box mx="auto" textAlign="center" my={20}>
        <Text fontSize="xl" color="gray.400">
          Belum Ada Pengetahuan Terbaru
        </Text>
      </Box>
    );
  }

  return (
    <Carousel responsive={responsive}>
      {listData.map((v: any) => (
        <CardItem item={v} key={v.id} accessFor="pengetahuan" />
      ))}
    </Carousel>
  );
};

/**
 * KnowledgeCenter component.
 *
 * @returns {React.FC} The KnowledgeCenter component.
 * @author Muhammad Farras Jibran
 */

const KnowledgeCenter: React.FC<KnowledgeCenterProps> = ({
  title,
  type,
  navigatePath,
}) => {
  const { isLoading, listData, listDataSync } = usePengetahuanContext().state;
  const { fetchData } = usePengetahuanContext();

  useEffect(() => {
    fetchData({ jenis_pengetahuan: type });
  }, []);

  const displayedData = type === "synchronized" ? listDataSync : listData;

  return (
    <Box bgGradient="linear(green.50 0%, white 100%)">
      <Container maxW="8xl" py={{ base: 8, md: 12 }} w="full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Text
            as="h2"
            fontWeight="semibold"
            fontSize={{ base: "xl", md: "2xl" }}
            letterSpacing="tight"
          >
            {title}
          </Text>

          <Link
            href={navigatePath}
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="BluePrimary.900"
            _hover={{ color: "BluePrimary.900" }}
          >
            Lihat Semua
          </Link>
        </Box>
        <Box mt={5}>
        <Box mt={5}>
          {isLoading ? (
            <Skeleton
              count={4}
              style={{ height: "400px", width: "280px", marginRight: "20px" }}
              inline={true}
            />
          ) : (
            <KnowledgeCarousel listData={displayedData} />
          )}
        </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default KnowledgeCenter;
