import { DashboardRekapitulasiRepositoryInterface } from "../../../data/contracts/DashboardRekapitulasi";
import { DashboardRekapitulasiDataMapper } from "../../../data/mappers/DashboardRekapitulasi";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { DashboardRekapitulasi } from "../../../entities/DashboardRekapitulasi";

export class DashboardRekapitulasiApiRepository
  implements DashboardRekapitulasiRepositoryInterface
{
  private apiService: ApiService;
  private mapper: DashboardRekapitulasiDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: DashboardRekapitulasiDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   *
   * @param page
   * @param rows
   * @param keyword
   * @param tahun
   * @returns
   */
  public async getDashboardRekapitulasiData(
    page: number,
    rows: number,
    keyword: string,
    tahun: string,
    is_simdiklat: boolean,
    event_id: string
  ): Promise<DashboardRekapitulasi[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.dashboardRekapitulasi(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        tahun: tahun,
        is_simdiklat: is_simdiklat,
        event_id,
      },
      null,
      headers,
    );
    return this.mapper.convertListDataDasboardRekapitulasi(dataResponse);
  }
}
