/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../../data/presenters/MateriPresenter";
import { UsersPresenter } from "../../../../../../data/presenters/UsersPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import { Users } from "../../../../../../entities/User";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";
import { PathMateriPresenter } from "../../../../../../data/presenters/PathMateriPresenter";
import update from "immutability-helper";

interface IState {
  isLoading: boolean;
  path_thumbnail: any;
  jenis_materi: any;
  path_materi: any;
  jenis_pelatihan: string;
  judul_materi: string;
  uraian_deskripsi: string;
  kompetensi: string;
  jam_pelatihan: string;
  status_konsultasi: boolean;
  widyaiswara: any[];
  status_publish: boolean;
  penyusun: string;
  listWidyaiswara: Users[];
  jenisPelatihan: JenisPelatihan[];
  metode_pilihan: any;
  jenisMateriArray: any[];
  fileMateriArray: any[];
}

interface InitialState {
  state: IState;
  createMateri: Function;
  setPathThumbnail: Function;
  setJenisMateri: Function;
  setPathMateri: Function;
  setJenisPelatihan: Function;
  setJudulMateri: Function;
  setUraianDeskripsi: Function;
  setKompetensi: Function;
  setJamPelatihan: Function;
  setStatusKosultasi: Function;
  setWidyaiswara: Function;
  setStatusPublish: Function;
  setPenyusun: Function;
  getListWidyaiswara: Function;
  getJenisPelatihan: Function;
  setMetodePilihan: Function;
  setPilihanVideo: Function;
  addFileMateri: Function;
  deleteFileMateri: Function;
}

const initialState = {
  state: {
    isLoading: false,
    path_thumbnail: null,
    jenis_materi: null,
    path_materi: null,
    jenis_pelatihan: "",
    judul_materi: "",
    uraian_deskripsi: "",
    kompetensi: "",
    jam_pelatihan: "",
    status_konsultasi: false,
    widyaiswara: [],
    status_publish: false,
    penyusun: "",
    listWidyaiswara: [],
    jenisPelatihan: [],
    metode_pilihan: null,
    jenisMateriArray: [],
    fileMateriArray: [],
  },
  createMateri: () => {},
  setPathThumbnail: () => {},
  setJenisMateri: () => {},
  setPathMateri: () => {},
  setJenisPelatihan: () => {},
  setJudulMateri: () => {},
  setUraianDeskripsi: () => {},
  setKompetensi: () => {},
  setJamPelatihan: () => {},
  setStatusKosultasi: () => {},
  setWidyaiswara: () => {},
  setStatusPublish: () => {},
  setPenyusun: () => {},
  getListWidyaiswara: () => {},
  getJenisPelatihan: () => {},
  setMetodePilihan: () => {},
  setPilihanVideo: () => {},
  addFileMateri: () => {},
  deleteFileMateri: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenMateriCreateProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    path_thumbnail: null,
    jenis_materi: null,
    path_materi: null,
    jenis_pelatihan: "",
    judul_materi: "",
    uraian_deskripsi: "",
    kompetensi: "",
    jam_pelatihan: "",
    status_konsultasi: false,
    widyaiswara: [],
    status_publish: false,
    penyusun: "",
    listWidyaiswara: [],
    jenisPelatihan: [],
    metode_pilihan: null,
    jenisMateriArray: [],
    fileMateriArray: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const createPathMateri = async (materi_id: number) => {
    try {
      const pathMateriPresenter = container.resolve(PathMateriPresenter);

      const promise = state.fileMateriArray.map(async (val) => {
        await pathMateriPresenter.postPathMateri(
          materi_id,
          val.path_materi,
          val.jenis_materi,
          val.metode_pilihan,
        );
      });

      await Promise.all(promise).then(() => {
        toast.success("Berhasil Menambahkan Data Baru.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_materi`);
        window.location.reload();
        setLoading(false);
      });
    } catch (error: any) {
      console.log("error post path materi :", error);
      toast.error("Ada Kesalahan Saat Membuat Materi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const checkPathMateri = (data) => {
    return data.every((item) => item.path_materi !== null);
  };

  const createMateri = async () => {
    setLoading(true);

    if (
      state.path_thumbnail == null ||
      state.jenis_pelatihan == "" ||
      state.judul_materi == "" ||
      state.uraian_deskripsi == "" ||
      state.kompetensi == "" ||
      state.jam_pelatihan == "" ||
      state.penyusun == ""
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.fileMateriArray.length == 0) {
      toast.error("Anda Belum Menambahkan Path Materi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.fileMateriArray.length > 0) {
      const isValid = checkPathMateri(state.fileMateriArray);

      if (!isValid) {
        toast.error("File Materi Harus Diisi!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setLoading(false);
        return;
      }
    }

    try {
      const materiPresenter = container.resolve(MateriPresenter);

      const listStringWidyaiswara: number[] = state.widyaiswara.map(
        (w) => w.value,
      );

      const store = await materiPresenter.storeMateri(
        state.path_thumbnail[0],
        state.jenis_pelatihan,
        state.judul_materi,
        state.uraian_deskripsi,
        state.kompetensi,
        state.jam_pelatihan,
        state.status_konsultasi,
        listStringWidyaiswara,
        state.status_publish,
        state.penyusun,
      );

      const data = store.data;

      if (data.code == 200) {
        createPathMateri(data.data.id);
      }
    } catch (error: any) {
      console.log("error materi:", error);
      toast.error("Ada Kesalahan Saat Tambah Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const setPathThumbnail = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_thumbnail: value,
    }));
  };

  const setJenisMateri = (value: string, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      jenis_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setPilihanVideo = (value, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      path_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setPathMateri = (value, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      path_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis_pelatihan: value,
    }));
  };

  const setJudulMateri = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      judul_materi: value,
    }));
  };

  const setUraianDeskripsi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      uraian_deskripsi: value,
    }));
  };

  const setKompetensi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      kompetensi: value,
    }));
  };

  const setJamPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jam_pelatihan: value,
    }));
  };

  const setStatusKosultasi = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      status_konsultasi: value,
    }));
  };

  const setWidyaiswara = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      widyaiswara: value,
    }));
  };

  const setStatusPublish = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      status_publish: value,
    }));
  };

  const setPenyusun = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      penyusun: value,
    }));
  };

  const setMetodePilihan = (value, idx) => {
    const updatedData = update(state.fileMateriArray[idx], {
      metode_pilihan: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const getListWidyaiswara = async () => {
    try {
      const usersPresenter = container.resolve(UsersPresenter);

      const get = await usersPresenter.getAllUsers(1, 10000, "", "Widyaiswara");

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listWidyaiswara: data,
      }));
    } catch (error: any) {
      console.log("error get User Widyaiswara:", error);
    }
  };

  const getJenisPelatihan = async () => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        "",
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const addFileMateri = () => {
    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: [
        ...prevstate.fileMateriArray,
        { path_materi: null, jenis_materi: null, metode_pilihan: null },
      ],
    }));
  };

  const deleteFileMateri = (idx: number) => {
    const newFileMateri = update(state.fileMateriArray, {
      $splice: [[idx, 1]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newFileMateri,
    }));
  };

  return (
    <ManajemenMateriCreateProvider
      value={{
        state,
        createMateri,
        setPathThumbnail,
        setJenisMateri,
        setPathMateri,
        setJenisPelatihan,
        setJudulMateri,
        setUraianDeskripsi,
        setKompetensi,
        setJamPelatihan,
        setStatusKosultasi,
        setWidyaiswara,
        setStatusPublish,
        setPenyusun,
        getListWidyaiswara,
        getJenisPelatihan,
        setMetodePilihan,
        setPilihanVideo,
        addFileMateri,
        deleteFileMateri,
      }}
    >
      {children}
    </ManajemenMateriCreateProvider>
  );
};

export const useManajemenMateriCreateContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenMateriCreateContext,
  Provider,
};
