/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { SoalPresenter } from "../../../../../../data/presenters/SoalPresenter";
import { JawabanSoalPresenter } from "../../../../../../data/presenters/JawabanSoalPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import { Jawaban, Soal } from "../../../../../../entities/PaketSoal";
import update from "immutability-helper";

interface IState {
	isLoading: boolean;
	isLoadingFetchData: boolean;
	soal: string;
	detailSoal: Soal | null;
	jawaban: any;
	jawabanBener: string;
	jawabanBenerArray: any;
	dataJawaban: any;
	opsiJawaban: any;
	increment: string;
	jawabanArray: any;
}

interface InitialState {
	state: IState;
	updateSoal: Function;
	setJawabanBenar: Function;
	setJawabanBenarArray: Function;
	setSoal: Function;
	setPilihan: Function;
	getDetailSoal: Function;
	setIncrement: Function;
	deleteJawaban: Function;
}

const initialState = {
	state: {
		isLoading: false,
		isLoadingFetchData: false,
		paket_soal_id: 0,
		soal: "",
		detailSoal: null,
		jawabanBener: "",
		jawabanBenerArray: [],
		jawaban: [],
		dataJawaban: [],
		opsiJawaban: [],
		increment: "a",
		jawabanArray: [],
	},
	updateSoal: () => {},
	setShowDialogPetunjuk: () => {},
	setJawabanBenar: () => {},
	setSoal: () => {},
	setPilihan: () => {},
	setActionStore: () => {},
	getDetailSoal: () => {},
	setIncrement: () => {},
	deleteJawaban: () => {},
	setJawabanBenarArray: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSoalUpdateProvider } = Context;

const Provider: React.FC = ({ children }) => {
	const [state, setState] = React.useState<IState>({
		isLoading: false,
		isLoadingFetchData: false,
		soal: "",
		detailSoal: null,
		jawabanBener: "",
		jawabanBenerArray: [],
		jawaban: [],
		dataJawaban: [],
		opsiJawaban: [],
		increment: "a",
		jawabanArray: [],
	});

	const setLoading = (value: boolean) => {
		setState((prevstate) => ({
			...prevstate,
			isLoading: value,
		}));
	};

	const setLoadingFetchData = (value: boolean) => {
		setState((prevstate) => ({
			...prevstate,
			isLoadingFetchData: value,
		}));
	};

	const setSoal = (value: string) => {
		setState((prevstate) => ({
			...prevstate,
			soal: value,
		}));
	};

	const updateJawaban = (id: number) => {
		try {
			const jawabanSoalPresenter =
				container.resolve(JawabanSoalPresenter);

			state.dataJawaban.map(async (val, idx) => {
				if (state.jawaban[idx]) {
					await jawabanSoalPresenter.updateJawabanSoal(
						state.jawaban[idx].id,
						val.value,
						val.jawaban_benar
					);
				} else {
					await jawabanSoalPresenter.storeJawabanSoal(
						id,
						val.value,
						val.jawaban_benar
					);
				}
			});
		} catch (error: any) {
			console.log("error update jawaban :", error);
			toast.error("Ada Kesalahan Saat Mengubah Data Ini!", {
				style: {
					boxShadow: "0px 1px 6px #F86E70",
				},
			});
		}
	};

	const showError = (message: string) => {
		toast.error(message, {
			style: {
				boxShadow: "0px 1px 6px #F86E70",
			},
		});
	};

	const updateSoal = async (id: number, paket_soal_id: number) => {
		// Validasi untuk memeriksa apakah ada jawaban benar yang bernilai true
		const getIndexPilihan = state.dataJawaban.findIndex(
			(v) => v.jawaban_benar == true
		);

		// Validasi untuk memeriksa apakah ada value yang kosong
		const hasEmptyValue = state.dataJawaban.some(
			(v) => v.value.trim() === ""
		);

		if (state.soal == "") {
			showError("Soal Belum Terisi!");
			return;
		}

		if (getIndexPilihan === -1) {
			showError("Anda belum memilih jawaban benar!");
			return;
		}

		if (hasEmptyValue) {
			showError("Semua pilihan jawaban harus diisi!");
			return;
		}

		setLoading(true);

		updateJawaban(paket_soal_id);

		try {
			console.log("soal", state);
			const soalPresenter = container.resolve(SoalPresenter);
			const update = await soalPresenter.updateSoal(
				paket_soal_id,
				state.soal
			);

			const data = update;

			if (data.data.code == 200) {
				toast.success("Berhasil Mengubah Data Ini.", {
					style: {
						boxShadow: "0px 1px 6px #019166",
					},
				});
				history.replace(`/admin/detail/manajemen_soal/${id}`);
				window.location.reload();
			} else {
				toast.error("Ada Kesalahan Saat Mengubah Data Ini!", {
					style: {
						boxShadow: "0px 1px 6px #F86E70",
					},
				});
			}
			setLoading(false);
		} catch (error: any) {
			console.log("error update soal :", error);
			toast.error("Ada Kesalahan Saat Mengubah Data Ini!", {
				style: {
					boxShadow: "0px 1px 6px #F86E70",
				},
			});
			setLoading(false);
		}
	};

	const setJawabanBenar = (idx: number, jenis_soal) => {
		if (jenis_soal == "Pilihan Ganda Kompleks") {
			const checkJawabanBefore = state.dataJawaban[idx];
			if (checkJawabanBefore.jawaban_benar == true) {
				// Set if jawaban same to false
				const jawabanBefore = update(state.dataJawaban[idx], {
					jawaban_benar: { $set: 0 },
				});
				const dataJawabanBefore = update(state.dataJawaban, {
					$splice: [[idx, 1, jawabanBefore]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: dataJawabanBefore,
				}));
			} else {
				const updateJawabanData = update(state.dataJawaban[idx], {
					jawaban_benar: { $set: 1 },
				});
				const newDataJawaban = update(state.dataJawaban, {
					$splice: [[idx, 1, updateJawabanData]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: newDataJawaban,
				}));
			}
		} else {
			const checkJawabanBefore = state.dataJawaban.findIndex(
				(v) => v.jawaban_benar == 1
			);
			if (checkJawabanBefore != -1) {
				// Set Jawaban Before To False
				const jawabanBefore = update(
					state.dataJawaban[checkJawabanBefore],
					{
						jawaban_benar: { $set: 0 },
					}
				);
				const dataJawabanBefore = update(state.dataJawaban, {
					$splice: [[checkJawabanBefore, 1, jawabanBefore]],
				});

				// Set Jawaban New To True
				const updateJawabanData = update(state.dataJawaban[idx], {
					jawaban_benar: { $set: 1 },
				});
				const newDataJawaban = update(dataJawabanBefore, {
					$splice: [[idx, 1, updateJawabanData]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: newDataJawaban,
				}));
			} else {
				const updateJawabanData = update(state.dataJawaban[idx], {
					jawaban_benar: { $set: 1 },
				});
				const newDataJawaban = update(state.dataJawaban, {
					$splice: [[idx, 1, updateJawabanData]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: newDataJawaban,
				}));
			}
		}
	};

	const setJawabanBenarArray = (val: string) => {
		const findData = state.jawabanBenerArray.findIndex((v) => v == val);

		if (findData != -1) {
			const newData = update(state.jawabanBenerArray, {
				$splice: [[findData, 1]],
			});

			setState((prevstate) => ({
				...prevstate,
				jawabanBenerArray: newData,
			}));
		} else {
			setState((prevstate) => ({
				...prevstate,
				jawabanBenerArray: [...prevstate.jawabanBenerArray, val],
			}));
		}
	};

	const setPilihan = (value: string, pilihan: string) => {
		if (state.dataJawaban.length == 0) {
			setState((prevstate) => ({
				...prevstate,
				dataJawaban: [
					...prevstate.dataJawaban,
					{ pilihan: pilihan, value: value, jawaban_benar: 0 },
				],
			}));
		} else {
			const getIndexPilihan = state.dataJawaban.findIndex(
				(x) => x.pilihan == pilihan
			);
			if (getIndexPilihan != -1) {
				const updatedData = update(state.dataJawaban[getIndexPilihan], {
					pilihan: { $set: pilihan },
					value: { $set: value },
				});

				const newData = update(state.dataJawaban, {
					$splice: [[getIndexPilihan, 1, updatedData]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: newData,
				}));
			} else {
				setState((prevstate) => ({
					...prevstate,
					dataJawaban: [
						...prevstate.dataJawaban,
						{ pilihan: pilihan, value: value, jawaban_benar: 0 },
					],
				}));
			}
		}
	};

	const getDetailSoal = async (id: number, jenis_soal: string) => {
		setLoadingFetchData(true);
		try {
			const soalPresenter = container.resolve(SoalPresenter);
			const getDetail = await soalPresenter.getDetailSoal(id);

			const data = getDetail;

			setState((prevstate) => ({
				...prevstate,
				detailSoal: data,
				soal: data.soal,
				jawaban: data.jawaban,
			}));

			const opsiArray = "abcdefghijklmnopqrstuvwxyz".split("");

			if (jenis_soal == "Pilihan Ganda Kompleks") {
				data.jawaban.map((j, idx) => {
					if (j.jawaban_benar == 1) {
						setState((prevstate) => ({
							...prevstate,
							jawabanBenerArray: [
								...prevstate.jawabanBenerArray,
								opsiArray[idx],
							],
						}));
					}

					return setState((prevstate) => ({
						...prevstate,
						opsiJawaban: [...prevstate.opsiJawaban, opsiArray[idx]],
						increment: opsiArray[idx],
						dataJawaban: [
							...prevstate.dataJawaban,
							{
								pilihan: opsiArray[idx],
								value: j.pilihan,
								jawaban_benar: j.jawaban_benar,
							},
						],
						jawabanArray: [
							...prevstate.dataJawaban,
							{
								pilihan: opsiArray[idx],
								value: j.pilihan,
								jawaban_benar: j.jawaban_benar,
							},
						],
					}));
				});
			} else {
				data.jawaban.map((j, idx) => {
					if (j.jawaban_benar == 1) {
						setState((prevstate) => ({
							...prevstate,
							jawabanBener: opsiArray[idx],
						}));
					}

					return setState((prevstate) => ({
						...prevstate,
						opsiJawaban: [...prevstate.opsiJawaban, opsiArray[idx]],
						increment: opsiArray[idx],
						dataJawaban: [
							...prevstate.dataJawaban,
							{
								pilihan: opsiArray[idx],
								value: j.pilihan,
								jawaban_benar: j.jawaban_benar,
							},
						],
						jawabanArray: [
							...prevstate.dataJawaban,
							{
								pilihan: opsiArray[idx],
								value: j.pilihan,
								jawaban_benar: j.jawaban_benar,
							},
						],
					}));
				});
			}

			setLoadingFetchData(false);
		} catch (error: any) {
			console.log("error fetch soal :", error);
			toast.error("Ada Kesalahan Saat Pengambilan Data", {
				style: {
					boxShadow: "0px 1px 6px #F86E70",
				},
			});
			setLoadingFetchData(false);
		}
	};

	const setIncrement = (value: string) => {
		if (value == "z") {
			toast.error(
				"Anda sudah sampai batas maksimum penambahan jawaban!",
				{
					style: {
						boxShadow: "0px 1px 6px #F86E70",
					},
				}
			);
			return;
		} else {
			setState((prevstate) => ({
				...prevstate,
				increment: String.fromCharCode(value.charCodeAt(0) + 1),
				opsiJawaban: prevstate.opsiJawaban.concat(
					String.fromCharCode(value.charCodeAt(0) + 1)
				),
				jawabanArray: [
					...prevstate.jawabanArray,
					{
						pilihan: String.fromCharCode(value.charCodeAt(0) + 1),
						value: "",
						jawaban_benar: false,
					},
				],
				dataJawaban: [
					...state.dataJawaban,
					{
						pilihan: String.fromCharCode(value.charCodeAt(0) + 1),
						value: "",
						jawaban_benar: false,
					},
				],
			}));

			if (state.dataJawaban.length > 0) {
				setState((prevstate) => ({
					...prevstate,
					dataJawaban: state.dataJawaban,
				}));
			}
		}
	};

	const deleteJawaban = async (idx: number) => {
		const detailJawaban: Jawaban = state.jawaban[idx];

		if (state.dataJawaban.length == 1) {
			showError("Anda harus memilih minimal 1 pilihan jawaban!");
			return;
		}

		if (detailJawaban) {
			try {
				const newDataPilihan = update(state.dataJawaban, {
					$splice: [[idx, 1]],
				});
				const newDataOpsi = update(state.opsiJawaban, {
					$splice: [[idx, 1]],
				});
				const newJawaban = update(state.jawaban, {
					$splice: [[idx, 1]],
				});

				setState((prevstate) => ({
					...prevstate,
					dataJawaban: newDataPilihan,
					opsiJawaban: newDataOpsi,
					jawabanArray: newDataPilihan,
					jawaban: newJawaban,
				}));

				const jawabanSoalPresenter =
					container.resolve(JawabanSoalPresenter);
				await jawabanSoalPresenter.deleteJawabanSoal(detailJawaban.id);
			} catch (error: any) {
				console.log("error when delete jawaban :", error);
				toast.error("Error saat hapus jawaban", {
					style: {
						boxShadow: "0px 1px 6px #F86E70",
					},
				});
			}
		} else {
			const newDataPilihan = update(state.dataJawaban, {
				$splice: [[idx, 1]],
			});
			const newDataOpsi = update(state.opsiJawaban, {
				$splice: [[idx, 1]],
			});

			setState((prevstate) => ({
				...prevstate,
				dataJawaban: newDataPilihan,
				opsiJawaban: newDataOpsi,
				jawabanArray: newDataPilihan,
			}));
		}
	};

	return (
		<ManajemenSoalUpdateProvider
			value={{
				state,
				updateSoal,
				setJawabanBenar,
				setSoal,
				setPilihan,
				getDetailSoal,
				setIncrement,
				deleteJawaban,
				setJawabanBenarArray,
			}}
		>
			{children}
		</ManajemenSoalUpdateProvider>
	);
};

export const useManajemenSoalUpdateContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
	useManajemenSoalUpdateContext,
	Provider,
};
