/* eslint-disable eqeqeq */
import { UsersRepositoryInterface } from "../../../data/contracts/Users";
import { UsersDataMapper } from "../../../data/mappers/Users";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ManagemenUser, Users } from "../../../entities/User";

export class UsersApiRepository implements UsersRepositoryInterface {
  private apiService: ApiService;
  private mapper: UsersDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: UsersDataMapper,
    endpoints: Endpoints,
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllUsers(
    page: number,
    rows: number,
    keyword: string,
    level: string,
  ): Promise<Users[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.user(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        level: level,
      },
      null,
      headers,
    );
    return this.mapper.convertListUserDataFromApi(dataResponse);
  }

  public async getUser(): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.profile(),
      {},
      null,
      headers,
    );
    return this.mapper.convertListUserDetailFromApi(dataResponse);
  }

  public async updateProfile(
    name: string,
    email: string,
    pangkat: string,
    golongan: string,
    jabatan: string,
    instansi: string,
    tempat_tugas: string,
    nik: string,
    jenis_kelamin: string,
    tempat_lahir: string,
    tanggal_lahir: string,
    provinsi_id: number,
    kabupaten_id: number,
    kecamatan_id: number,
    kelurahan_id: number,
    alamat: string,
    no_hp: string,
    agama: string,
    status_kawin: string,
    status_pegawai: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.updateProfile(),
      {
        name: name,
        email: email,
        gol_ruang: golongan,
        pangkat: pangkat,
        level_jabatan: jabatan,
        satuan_kerja: instansi,
        tempat_tugas: tempat_tugas,
        nik: nik,
        jenis_kelamin: jenis_kelamin,
        tempat_lahir: tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        provinsi_id: provinsi_id,
        kabupaten_id: kabupaten_id,
        kecamatan_id: kecamatan_id,
        kelurahan_id: kelurahan_id,
        alamat: alamat,
        no_hp: no_hp,
        agama: agama,
        status_kawin: status_kawin,
        status_pegawai: status_pegawai,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async updatePassword(
    old_password: string,
    password: string,
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.updatePassword(),
      {
        old_password: old_password,
        password: password,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async updatePhoto(path_photo: File): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("path_foto", path_photo);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.updateProfilePhoto(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateProfileSimpeg(): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.updateProfileSimpeg(),
      null,
      null,
      headers,
    );

    if (dataResponse.data.data === null) {
      return dataResponse;
    } else {
      return this.mapper.convertListUserDetailFromApi(dataResponse);
    }
  }

  public async checkNip(nip: string): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.cek_nip(),
      {
        nip: nip,
      },
      null,
      headers,
    );
    return dataResponse;
  }

  public async getAllManagemenUser(
    page: number,
    rows: number,
    keyword: string,
    internal: boolean,
    peserta: boolean,
  ): Promise<ManagemenUser[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.managemenUser(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        internal: internal,
        peserta: peserta,
      },
      null,
      headers,
    );
    return this.mapper.convertListManagemenUserDataFromApi(dataResponse);
  }

  public async createManagemenUser(
    name: string,
    email: string,
    nik: string,
    nip: string,
    jabatan: string,
    level: string,
    phone_number: string,
    password: string,
    password_confirmation: string,
    path_foto: File,
    is_kemenag: any,
    tempat_lahir: string,
    tanggal_lahir: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    if (level === "peserta" || level === "widyaiswara") {
      formData.append("nik", nik);
      formData.append("nip", nip);
    }
    formData.append("jabatan", jabatan);
    formData.append("level", level);
    formData.append("phone_number", phone_number);
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    formData.append("jenis_user", is_kemenag.toString());
    formData.append("tempat_lahir", tempat_lahir);
    formData.append("tanggal_lahir", tanggal_lahir);
    if (path_foto != null) {
      formData.append("path_foto", path_foto);
    }
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.managemenUser(),
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async updateManagemenUser(
    id: number,
    name: string,
    email: string,
    nik: string,
    nip: string,
    jabatan: string,
    level: string,
    phone_number: string,
    password: string,
    password_confirmation: string,
    path_foto: File,
    is_kemenag: any,
    tempat_lahir: string,
    tanggal_lahir: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("nik", nik);
    formData.append("nip", nip);
    formData.append("jabatan", jabatan);
    formData.append("level", level);
    formData.append("phone_number", phone_number);
    formData.append("tempat_lahir", tempat_lahir);
    formData.append("tanggal_lahir", tanggal_lahir);
    if (password != "" && password_confirmation != "") {
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);
    }
    formData.append("jenis_user", is_kemenag.toString());
    if (path_foto != null) {
      formData.append("path_foto", path_foto);
    }
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.managemenUser() + "/" + id,
      null,
      formData,
      headers,
    );
    return dataResponse;
  }

  public async deleteManagemenUser(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.managemenUser() + "/" + id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }

  public async resetPasswordManagemenUser(user_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.user() + "/reset-default-password/" + user_id,
      {},
      null,
      headers,
    );
    return dataResponse;
  }
}
