import { useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface EventPintarItem {
  id: number;
  title: string;
  training_start_date: string;
  training_end_date: string;
  location: string;
  image: string;
  description: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}
interface IState {
  state: {
    title: string;
    image: File | null;
    description: string;
    publish: boolean;
    startDate: string;
    endDate: string;
    isOnline: boolean;
    isOffline: boolean;
    listData: EventPintarItem[];
    mode: string;
    itemId: string;
    loadingData: boolean;
    loadingAction: boolean;
    url: string;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setEventPintarState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
  handleEdit: Function;
  clearState: Function;
}

const initialState: IState = {
  state: {
    title: "",
    image: null,
    description: "",
    publish: false,
    startDate: "",
    endDate: "",
    isOnline: false,
    isOffline: false,
    mode: "",
    itemId: "",
    listData: [],
    loadingData: false,
    loadingAction: false,
    url: "management_content/event_pintar",
    pagination: null,
    page: 1,
    perPage: 9,
  },
  setEventPintarState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
  handleEdit: () => {},
  clearState: () => {},
};

/**
 * The EventPintarContext provides a context for managing the state related to the event pintar.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: EventPintarProvider } = Context;

/**
 * The provider component that wraps its children with the EventPintarContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    title,
    description,
    image,
    publish,
    mode,
    itemId,
    listData,
    startDate,
    endDate,
    isOffline,
    isOnline,
    url,
    perPage,
    page,
  } = state.state;

  const setEventPintarState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const clearState = () => {
    setEventPintarState("title", "");
    setEventPintarState("description", "");
    setEventPintarState("startDate", "");
    setEventPintarState("endDate", "");
    setEventPintarState("isOnline", false);
    setEventPintarState("isOffline", false);
    setEventPintarState("publish", false);
    setEventPintarState("image", null);
  };

  const fetchData = async (status?: string) => {
    setEventPintarState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      setEventPintarState("listData", response.data?.data);
      setEventPintarState("pagination", response?.data.pagination);
      setEventPintarState("loadingData", false);
    } catch (error) {
      throw error;
    }
  };

  const postData = async () => {
    if (title.length > 30) {
      return toast({
        title: "Maksimal judul 30 karakter",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
    setEventPintarState("loadingAction", true);

    const trainingStartDate = moment(startDate);
    const formattedTrainingStartDate = trainingStartDate.format(
      "YYYY-MM-DD HH:mm:ss",
    );

    const trainingEndDate = moment(endDate);
    const formattedTrainingEndDate = trainingEndDate.format(
      "YYYY-MM-DD HH:mm:ss",
    );

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("training_start_date", formattedTrainingStartDate);
    formData.append("training_end_date", formattedTrainingEndDate);
    formData.append("status_publish", publish ? "1" : "0");
    formData.append(
      "location",
      isOffline ? "offline" : isOnline ? "online" : "",
    );
    if (image instanceof File) {
      formData.append("image", image);
    }

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";
      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      setEventPintarState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      setEventPintarState("mode", "");

      fetchData();

      return response.data;
    } catch (error: any) {
      setEventPintarState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });

      throw error;
    }
  };

  const handleEdit = (id) => {
    const dataEdit = listData.find((item) => item.id === id);
    if (dataEdit) {
      setEventPintarState("mode", "edit");
      setEventPintarState("title", dataEdit?.title);
      setEventPintarState("description", dataEdit?.description);
      setEventPintarState("startDate", dataEdit?.training_start_date);
      setEventPintarState("endDate", dataEdit?.training_end_date);
      setEventPintarState(
        "isOnline",
        dataEdit?.location === "online" ? true : false,
      );
      setEventPintarState(
        "isOffline",
        dataEdit?.location === "offline" ? true : false,
      );
      setEventPintarState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true,
      );
      setEventPintarState("image", dataEdit?.image);
      setEventPintarState("itemId", id);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };
  return (
    <EventPintarProvider
      value={{
        state: state.state,
        setEventPintarState,
        fetchData,
        postData,
        handleEdit,
        handleDelete,
        clearState,
      }}
    >
      {children}
    </EventPintarProvider>
  );
};

export const useEventPintarContext = () => useContext(Context);

const EventPintarContext = {
  useEventPintarContext,
  Provider,
};

export default EventPintarContext;
