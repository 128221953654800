/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../../data/presenters/MateriPresenter";
import { UsersPresenter } from "../../../../../../data/presenters/UsersPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import { Users } from "../../../../../../entities/User";
import { Materi } from "../../../../../../entities/Materi";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";
import { PathMateriPresenter } from "../../../../../../data/presenters/PathMateriPresenter";
import update from "immutability-helper";
interface IState {
  isLoading: boolean;
  isLoadingDelete: boolean;
  path_thumbnail: any;
  jenis_materi: string;
  path_materi: any[];
  jenis_pelatihan: string;
  judul_materi: string;
  uraian_deskripsi: string;
  kompetensi: string;
  jam_pelatihan: string;
  status_konsultasi: boolean;
  widyaiswara: any[];
  status_publish: boolean;
  penyusun: string;
  listWidyaiswara: Users[];
  materi: Materi | null;
  jenisPelatihan: JenisPelatihan[];
  metode_pilihan: any;
  jenisMateriArray: any[];
  fileMateriArray: any[];
  pathMateriArray: any[];
}

interface InitialState {
  state: IState;
  updateMateri: Function;
  setPathThumbnail: Function;
  setJenisMateri: Function;
  setPathMateri: Function;
  setJenisPelatihan: Function;
  setJudulMateri: Function;
  setUraianDeskripsi: Function;
  setKompetensi: Function;
  setJamPelatihan: Function;
  setStatusKosultasi: Function;
  setWidyaiswara: Function;
  setStatusPublish: Function;
  setPenyusun: Function;
  getListWidyaiswara: Function;
  getDetailMateri: Function;
  getJenisPelatihan: Function;
  setMetodePilihan: Function;
  setPilihanVideo: Function;
  addFileMateri: Function;
  deleteFileMateri: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingDelete: false,
    path_thumbnail: null,
    jenis_materi: "",
    path_materi: [],
    jenis_pelatihan: "",
    judul_materi: "",
    uraian_deskripsi: "",
    kompetensi: "",
    jam_pelatihan: "",
    status_konsultasi: false,
    widyaiswara: [],
    status_publish: false,
    penyusun: "",
    listWidyaiswara: [],
    materi: null,
    jenisPelatihan: [],
    metode_pilihan: null,
    jenisMateriArray: [],
    fileMateriArray: [],
    pathMateriArray: [],
  },
  updateMateri: () => {},
  setPathThumbnail: () => {},
  setJenisMateri: () => {},
  setPathMateri: () => {},
  setJenisPelatihan: () => {},
  setJudulMateri: () => {},
  setUraianDeskripsi: () => {},
  setKompetensi: () => {},
  setJamPelatihan: () => {},
  setStatusKosultasi: () => {},
  setWidyaiswara: () => {},
  setStatusPublish: () => {},
  setPenyusun: () => {},
  getListWidyaiswara: () => {},
  getDetailMateri: () => {},
  getJenisPelatihan: () => {},
  setMetodePilihan: () => {},
  setPilihanVideo: () => {},
  addFileMateri: () => {},
  deleteFileMateri: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenMateriUpdateProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingDelete: false,
    path_thumbnail: null,
    jenis_materi: "video",
    path_materi: [],
    jenis_pelatihan: "",
    judul_materi: "",
    uraian_deskripsi: "",
    kompetensi: "",
    jam_pelatihan: "",
    status_konsultasi: false,
    widyaiswara: [],
    status_publish: false,
    penyusun: "",
    listWidyaiswara: [],
    materi: null,
    jenisPelatihan: [],
    metode_pilihan: null,
    jenisMateriArray: [],
    fileMateriArray: [],
    pathMateriArray: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingDeletePathMateri = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const updatePathMateri = async (materi_id: number) => {
    try {
      const pathMateriPresenter = container.resolve(PathMateriPresenter);

      const promise = state.fileMateriArray.map(async (val, idx) => {
        if (state.pathMateriArray[idx]) {
          await pathMateriPresenter.putPathMateri(
            state.path_materi[idx].id,
            val.path_materi,
            val.jenis_materi,
            val.metode_pilihan,
          );
        } else {
          await pathMateriPresenter.postPathMateri(
            materi_id,
            val.path_materi,
            val.jenis_materi,
            val.metode_pilihan,
          );
        }
      });

      await Promise.all(promise).then(() => {
        toast.success("Berhasil Mengubah Data Anda.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_materi`);
        window.location.reload();
        setLoading(false);
      });
    } catch (error: any) {
      console.log("error when update path materi :", error);
      toast.error("Ada Kesalahan Saat Mengupdate Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const checkPathMateri = (data) => {
    return data.every((item) => item.path_materi !== null);
  };

  const updateMateri = async (id: number) => {
    setLoading(true);

    if (
      state.jenis_materi == "" ||
      state.jenis_pelatihan == "" ||
      state.judul_materi == "" ||
      state.uraian_deskripsi == "" ||
      state.kompetensi == "" ||
      state.jam_pelatihan == ""
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.fileMateriArray.length == 0) {
      toast.error("Anda Belum Menambahkan Path Materi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.fileMateriArray.length > 0) {
      const isValid = checkPathMateri(state.fileMateriArray);

      if (!isValid) {
        toast.error("File Materi Harus Diisi!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setLoading(false);
        return;
      }
    }

    try {
      const materiPresenter = container.resolve(MateriPresenter);

      const listStringWidyaiswara: number[] = state.widyaiswara.map(
        (w) => w.value,
      );

      const store = await materiPresenter.updateMateri(
        id,
        state.path_thumbnail != null ? state.path_thumbnail[0] : "",
        state.jenis_pelatihan,
        state.judul_materi,
        state.uraian_deskripsi,
        state.kompetensi,
        state.jam_pelatihan,
        state.status_konsultasi,
        listStringWidyaiswara,
        state.status_publish,
        state.penyusun,
      );

      const data = store.data;

      if (data.code == 200) {
        updatePathMateri(id);
      } else {
        toast.error("Ada Kesalahan Saat Update Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      console.log("error materi:", error);
      toast.error("Ada Kesalahan Saat Update Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const setPathThumbnail = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_thumbnail: value,
    }));
  };

  const setJenisMateri = (value: string, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      jenis_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setPilihanVideo = (value, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      path_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setPathMateri = (value, idx: number) => {
    const updatedData = update(state.fileMateriArray[idx], {
      path_materi: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis_pelatihan: value,
    }));
  };

  const setJudulMateri = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      judul_materi: value,
    }));
  };

  const setUraianDeskripsi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      uraian_deskripsi: value,
    }));
  };

  const setKompetensi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      kompetensi: value,
    }));
  };

  const setJamPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jam_pelatihan: value,
    }));
  };

  const setMetodePilihan = (value, idx) => {
    const updatedData = update(state.fileMateriArray[idx], {
      metode_pilihan: { $set: value },
    });

    const newData = update(state.fileMateriArray, {
      $splice: [[idx, 1, updatedData]],
    });

    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: newData,
    }));
  };

  const setStatusKosultasi = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      status_konsultasi: value,
    }));
  };

  const setWidyaiswara = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      widyaiswara: value,
    }));
  };

  const setStatusPublish = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      status_publish: value,
    }));
  };

  const setPenyusun = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      penyusun: value,
    }));
  };

  const getListWidyaiswara = async (id: number) => {
    try {
      const usersPresenter = container.resolve(UsersPresenter);

      const get = await usersPresenter.getAllUsers(1, 10000, "", "Widyaiswara");

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listWidyaiswara: data,
      }));

      getDetailMateri(id, data);
    } catch (error: any) {
      console.log("error get User Widyaiswara:", error);
    }
  };

  const getDetailMateri = async (id: number, listWidyaiswara: Users[]) => {
    try {
      // Get Materi
      const materiPresenter = container.resolve(MateriPresenter);
      const getDetail = await materiPresenter.getDetailMateri(id);
      const data = getDetail;

      let listFixWidyaiswara: { value: number; label: string }[] = [];

      if (data.widyaiswara != null) {
        const dataWidyaiswara = data.widyaiswara.toString();

        const listWidya = dataWidyaiswara.split(",");

        listWidya.forEach((lw) => {
          const matchingWidya = listWidyaiswara.find(
            (l) => lw === l.id.toString(),
          );

          if (matchingWidya) {
            const obj: { value: number; label: string } = {
              value: matchingWidya.id,
              label: matchingWidya.name,
            };
            listFixWidyaiswara.push(obj);
          }
        });
      } else {
        listFixWidyaiswara = [];
      }

      // Get Path Materi
      const pathMateriPresenter = container.resolve(PathMateriPresenter);
      const get = await pathMateriPresenter.getAllPathMateri(id, "");
      const response = get;

      let listFixPathMateri: {
        path_materi: string;
        jenis_materi: string;
        metode_pilihan: number;
      }[] = [];

      if (response != null) {
        listFixPathMateri = response.map((val) => ({
          path_materi: val.path_materi,
          jenis_materi: val.jenis_materi,
          metode_pilihan: val.metode,
        }));
      }

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
        jenis_pelatihan: data.jenis_pelatihan_id.toString(),
        judul_materi: data.judul_materi,
        uraian_deskripsi: data.uraian_deskripsi,
        kompetensi: data.kompetensi,
        jam_pelatihan: data.jam_pelatihan,
        status_publish: data.status_publish == 1 ? true : false,
        status_konsultasi: data.status_konsultasi == 1 ? true : false,
        penyusun: data.penyusun,
        widyaiswara: listFixWidyaiswara,
        path_materi: response,
        pathMateriArray: listFixPathMateri,
        fileMateriArray: listFixPathMateri,
      }));
    } catch (error: any) {
      console.log("error get materi:", error);
    }
  };

  const getJenisPelatihan = async () => {
    try {
      const jenisPelatihanPresenter = container.resolve(
        JenisPelatihanPresenter,
      );

      const get = await jenisPelatihanPresenter.getAllJenisPelatihan(
        1,
        10000,
        "",
        "",
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const addFileMateri = () => {
    setState((prevstate) => ({
      ...prevstate,
      fileMateriArray: [
        ...prevstate.fileMateriArray,
        { path_materi: null, jenis_materi: null, metode_pilihan: null },
      ],
    }));
  };

  const deleteFileMateri = async (idx: number) => {
    const detailMateri = state.pathMateriArray[idx];

    if (detailMateri) {
      setLoadingDeletePathMateri(true);

      try {
        const pathMateriPresenter = container.resolve(PathMateriPresenter);
        await pathMateriPresenter.deletePathMateri(state.path_materi[idx].id);

        const newFileMateri = update(state.fileMateriArray, {
          $splice: [[idx, 1]],
        });

        const newPathMateri = update(state.path_materi, {
          $splice: [[idx, 1]],
        });

        setState((prevstate) => ({
          ...prevstate,
          fileMateriArray: newFileMateri,
          pathMateriArray: newFileMateri,
          path_materi: newPathMateri,
        }));

        toast.success("Berhasil Menghapus Path Materi", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
      } catch (error: any) {
        console.log("error when delete path materi :", error);
        toast.error("Ada Kesalahan Saat Menghapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setLoadingDeletePathMateri(false);
      }
    } else {
      const newFileMateri = update(state.fileMateriArray, {
        $splice: [[idx, 1]],
      });

      setState((prevstate) => ({
        ...prevstate,
        fileMateriArray: newFileMateri,
        pathMateriArray: newFileMateri,
      }));
    }
  };

  return (
    <ManajemenMateriUpdateProvider
      value={{
        state,
        updateMateri,
        setPathThumbnail,
        setJenisMateri,
        setPathMateri,
        setJenisPelatihan,
        setJudulMateri,
        setUraianDeskripsi,
        setKompetensi,
        setJamPelatihan,
        setStatusKosultasi,
        setWidyaiswara,
        setStatusPublish,
        setPenyusun,
        getListWidyaiswara,
        getDetailMateri,
        getJenisPelatihan,
        setMetodePilihan,
        setPilihanVideo,
        addFileMateri,
        deleteFileMateri,
      }}
    >
      {children}
    </ManajemenMateriUpdateProvider>
  );
};

export const useManajemenMateriUpdateContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenMateriUpdateContext,
  Provider,
};
