import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillPlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import CardItem from "../../../../components/Admin/Card/CardItem";
import EditorComponent from "../../../../components/Admin/Editor";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import BoxUploadFile from "../../../../components/Admin/UploadFile/BoxUploadFile";
import Pagination from "../../../../components/Pagination";
import EventPintarController, {
  useEventPintarContext,
} from "../../../../controller/admin/landing_page/event_pintar";

/**
 * EventPintar is a component that allows users to manage events.
 * It provides a form to create new events and displays a list of existing events.
 * Uses TinyMCE editor for description.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const EventPintar = () => {
  const {
    setEventPintarState,
    postData,
    handleDelete,
    handleEdit,
    fetchData,
    clearState,
  } = useEventPintarContext();
  const state = useEventPintarContext().state;

  const {
    publish,
    isOnline,
    isOffline,
    image,
    loadingData,
    listData,
    mode,
    title,
    startDate,
    endDate,
    description,
    loadingAction,
    pagination,
    perPage,
    page,
  } = state;

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const handlePageChange = (e) => {
    setEventPintarState("page", e.selected + 1);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            {mode !== "" && (
              <IconButton
                aria-label="Previous Page"
                icon={<AiOutlineArrowLeft />}
                fontSize="md"
                mr={2}
                onClick={() => setEventPintarState("mode", "")}
              />
            )}
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              {mode === "create" ? "Tambah" : mode === "edit" ? "Ubah" : ""}{" "}
              Event Pintar
            </Heading>
          </Flex>
          {(mode === "create" || mode === "edit") && publish ? (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Publish"
              onClick={() => postData()}
            >
              Publish
            </Button>
          ) : (mode === "create" || mode === "edit") && !publish ? (
            <Button
              color="white"
              bg="#3C4952"
              _hover={{ bg: "#3C4952" }}
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Simpan Draft"
              onClick={() => postData()}
            >
              Simpan Draft
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              onClick={() => {
                clearState();
                setEventPintarState("mode", "create");
              }}
            >
              Tambah Baru
            </Button>
          )}
        </Flex>
      </Box>
      {mode === "create" || mode === "edit" ? (
        <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* judul */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={title}
                onChange={(e) => setEventPintarState("title", e.target.value)}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            {/* Waktu Pelatihan */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Tanggal Mulai
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Input
                defaultValue={startDate}
                type="datetime-local"
                onChange={(e) =>
                  setEventPintarState("startDate", e.target.value)
                }
                w="50%"
                mr={4}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Tanggal Selesai
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Input
                defaultValue={endDate}
                type="datetime-local"
                onChange={(e) => setEventPintarState("endDate", e.target.value)}
                w="50%"
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "blue",
                  borderColor: "blue",
                }}
              />
            </GridItem>

            {/* Lokasi */}
            <GridItem colSpan={1} my={2}>
              <Text fontWeight="semibold" fontSize="sm">
                Lokasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Checkbox
                isChecked={isOffline}
                onChange={(e) => {
                  setEventPintarState("isOffline", e.target.checked);
                  if (isOffline) {
                    setEventPintarState("isOnline", true);
                  } else {
                    setEventPintarState("isOnline", false);
                  }
                }}
                colorScheme="blue"
                mr={4}
              >
                <Text fontSize="sm">Offline</Text>
              </Checkbox>
              <Checkbox
                isChecked={isOnline}
                onChange={(e) => {
                  setEventPintarState("isOnline", e.target.checked);
                  if (isOnline) {
                    setEventPintarState("isOffline", true);
                  } else {
                    setEventPintarState("isOffline", false);
                  }
                }}
                colorScheme="blue"
              >
                <Text fontSize="sm">Online</Text>
              </Checkbox>
            </GridItem>

            {/* Banner */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Cover Thumbnail
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setEventPintarState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Deskripsi
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <EditorComponent
                description={description}
                setDescription={setEventPintarState}
              />
            </GridItem>

            {/* Publikasi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Publikasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Switch
                defaultChecked={publish}
                colorScheme="blue"
                mr={2}
                onChange={(e) =>
                  setEventPintarState("publish", e.target.checked)
                }
              />
              <Text fontWeight="semibold" fontSize="sm">
                {publish ? "Publikasi" : "Draft"}
              </Text>
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={4} m={4}>
          {loadingData ? (
            <>
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
            </>
          ) : (
            listData.map((item) => (
              <GridItem colSpan={{ base: 3, md: 1 }} key={item.id}>
                <CardItem
                  item={item}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  image={item.image}
                  isEventPintar={true}
                  module="event pintar"
                />
              </GridItem>
            ))
          )}
        </Grid>
      )}
      {pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={2} mb={4} mr={3}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </SidebarWithHeader>
  );
};

const EventPintarMain: React.FC = () => {
  return (
    <EventPintarController.Provider>
      <EventPintar />
    </EventPintarController.Provider>
  );
};

export default EventPintarMain;
