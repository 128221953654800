import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";
import LogoIcourse from "../../../../assets/svg/logo-icourse.svg";

interface IState {
  state: {
    logoNavbar: File | null;
    logoFooterLeft: File | null;
    logoFooterCenter: File | null;
    logoNavbarUrl: string;
    logoFooterLeftUrl: string;
    logoFooterCenterUrl: string;
    contact: string;
    urlFacebook: string;
    urlInstagram: string;
    urlYoutube: string;
    urlTwitter: string;
    copyright: string;
    url: string;
    loadingAction: boolean;
    loadingData: boolean;
    isPreview: boolean;
    listData: [];
  };
  setManajemenIdentitasState: Function;
  setLogoNavbar: Function;
  setLogoFooterLeft: Function;
  setLogoFooterCenter: Function;
  postData: Function;
  fetchData: Function;
}

const initialState: IState = {
  state: {
    logoNavbar: null,
    logoFooterLeft: null,
    logoFooterCenter: null,
    logoNavbarUrl: "",
    logoFooterCenterUrl: "",
    logoFooterLeftUrl: "",
    contact: "",
    urlFacebook: "",
    urlInstagram: "",
    urlYoutube: "",
    urlTwitter: "",
    copyright: "",
    url: "settings/layout",
    loadingAction: false,
    loadingData: false,
    isPreview: false,
    listData: [],
  },
  setManajemenIdentitasState: () => {},
  setLogoNavbar: () => {},
  setLogoFooterLeft: () => {},
  setLogoFooterCenter: () => {},
  postData: () => {},
  fetchData: () => {},
};

/**
 * The ManajemenIdentitasrContext provides a context for managing the state related to manajemen identitas.
 * It includes state properties and functions to update the state and post data to a server.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: ManajemenIdentitasProvider } = Context;

/**
 * The provider component that wraps its children with the ManajemenIdentitasrContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Muhammad Farras Jibran
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    logoNavbar,
    logoFooterCenter,
    logoFooterLeft,
    url,
    contact,
    urlFacebook,
    urlInstagram,
    urlTwitter,
    urlYoutube,
    copyright,
    listData,
  } = state.state;

  const setManajemenIdentitasState = (key: string, value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const setLogoNavbar = (file: File | null) => {
    setManajemenIdentitasState("logoNavbar", file);
    setManajemenIdentitasState("logoNavbarUrl", URL.createObjectURL(file));
  };

  const setLogoFooterLeft = (file: File | null) => {
    setManajemenIdentitasState("logoFooterLeft", file);
    setManajemenIdentitasState("logoFooterLeftUrl", URL.createObjectURL(file));
  };

  const setLogoFooterCenter = (file: File | null) => {
    setManajemenIdentitasState("logoFooterCenter", file);
    setManajemenIdentitasState(
      "logoFooterCenterUrl",
      URL.createObjectURL(file),
    );
  };

  const postData = async () => {
    setManajemenIdentitasState("loadingAction", true);
    const formData = new FormData();
    formData.append("logo_navigator", logoNavbar ?? "");
    formData.append("logo_footer_kiri", logoFooterLeft ?? "");
    formData.append("logo_footer_tengah", logoFooterCenter ?? "");
    formData.append("kontak", contact);
    formData.append("url_facebook", urlFacebook);
    formData.append("url_twitter", urlTwitter);
    formData.append("url_youtube", urlYoutube);
    formData.append("url_instagram", urlInstagram);
    formData.append("copyright", copyright);

    try {
      const urlSave = `${url}/storeOrUpdate`;

      const response = await axiosInstance.post(`${urlSave}`, formData);
      setManajemenIdentitasState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error: any) {
      setManajemenIdentitasState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });

      throw error;
    }
  };

  const fetchData = async () => {
    setManajemenIdentitasState("loadingData", true);

    try {
      const response = await axiosInstance.get(url);

      setManajemenIdentitasState("listData", response.data?.data);

      const data = response.data?.data[0];

      setManajemenIdentitasState("logoNavbar", data?.path_logo_navigator ?? LogoIcourse);
      setManajemenIdentitasState("logoNavbarUrl", data?.path_logo_navigator ?? LogoIcourse);
      setManajemenIdentitasState("logoFooterLeft", data?.path_logo_footer_kiri ?? LogoIcourse);
      setManajemenIdentitasState(
        "logoFooterLeftUrl",
        data?.path_logo_footer_kiri ?? LogoIcourse,
      );
      setManajemenIdentitasState(
        "logoFooterCenter",
        data?.path_logo_footer_tengah ?? LogoIcourse,
      );
      setManajemenIdentitasState(
        "logoFooterCenterUrl",
        data?.path_logo_footer_tengah ?? LogoIcourse,
      );
      setManajemenIdentitasState("contact", data?.kontak ?? "0213456789");
      setManajemenIdentitasState("urlFacebook", data?.url_facebook ?? "");
      setManajemenIdentitasState("urlInstagram", data?.url_instagram ?? "");
      setManajemenIdentitasState("urlTwitter", data?.url_twitter ?? "");
      setManajemenIdentitasState("urlYoutube", data?.url_youtube ?? "");
      setManajemenIdentitasState("copyright", data?.copyright ?? "2024");

      setManajemenIdentitasState("loadingData", false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <ManajemenIdentitasProvider
      value={{
        state: state.state,
        setManajemenIdentitasState,
        postData,
        setLogoNavbar,
        setLogoFooterLeft,
        setLogoFooterCenter,
        fetchData,
      }}
    >
      {children}
    </ManajemenIdentitasProvider>
  );
};

export const useManajemenIdentitasContext = () => useContext(Context);

const ManajemenIdentitasContext = {
  useManajemenIdentitasContext,
  Provider,
};

export default ManajemenIdentitasContext;
