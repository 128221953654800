/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { KategoriPaketSoalPresenter } from "../../../../../../data/presenters/KategoriPaketSoalPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KategoriPaketSoal } from "../../../../../../entities/KategoriPaketSoal";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalKategoriPaketSoal: number;
  kategoriPaketSoal: KategoriPaketSoal[];
  formId: number;
  formDialog: string;
  formKategori: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
}

interface InitialState {
  state: IState;
  getKategoriPaketSoal: Function;
  setPage: Function;
  setPageRow: Function;
  searchKategoriPaketSoal: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormKategori: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deletePaketSoal: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKategoriPaketSoal: 1,
    kategoriPaketSoal: [],
    formDialog: "",
    formId: 0,
    formKategori: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  },
  getKategoriPaketSoal: () => {},
  searchKategoriPaketSoal: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormKategori: () => {},
  setShowDialog: () => {},
  deletePaketSoal: () => {},
  setShowDialogDelete: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterKategoriSoalProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKategoriPaketSoal: 0,
    kategoriPaketSoal: [],
    formDialog: "",
    formId: 0,
    formKategori: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getKategoriPaketSoal = async (
    pagee: number,
    keyword: string,
    row: number,
  ) => {
    setLoading(true);

    try {
      const kategoriPaketSoalPresenter = container.resolve(
        KategoriPaketSoalPresenter,
      );
      const getAllKategoriPaketSoal =
        await kategoriPaketSoalPresenter.getAllKategoriPaketSoal(
          pagee,
          row,
          keyword,
        );

      const data = getAllKategoriPaketSoal;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        kategoriPaketSoal: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchKategoriPaketSoal = (value: string) => {
    setLoading(true);
    getKategoriPaketSoal(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: KategoriPaketSoal) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formKategori: value.kategori_paket_soal,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formKategori == "") {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }
    
    try {
      const kategoriPaketSoalPresenter = container.resolve(
        KategoriPaketSoalPresenter,
      );
      if (state.formDialog == "Tambah") {
        const action = await kategoriPaketSoalPresenter.storeKategoriPaketSoal(
          state.formKategori,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKategoriPaketSoal(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await kategoriPaketSoalPresenter.updateKategoriPaketSoal(
          state.formId,
          state.formKategori,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKategoriPaketSoal(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormKategori = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKategori: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formKategori: "",
    }));
  };

  const deletePaketSoal = async () => {
    setFormLoading(true);

    try {
      const kategoriPaketSoalPresenter = container.resolve(
        KategoriPaketSoalPresenter,
      );
      const action = await kategoriPaketSoalPresenter.deleteKategoriPaketSoal(
        state.formId,
      );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getKategoriPaketSoal(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  return (
    <MasterKategoriSoalProvider
      value={{
        state,
        getKategoriPaketSoal,
        setPage,
        setPageRow,
        searchKategoriPaketSoal,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormKategori,
        setShowDialog,
        deletePaketSoal,
        setShowDialogDelete,
      }}
    >
      {children}
    </MasterKategoriSoalProvider>
  );
};

export const useMasterKategoriSoalContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterKategoriSoalContext,
  Provider,
};
