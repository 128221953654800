/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MenuJenisPelatihanPresenter } from "../../../../../../data/presenters/MenuJenisPelatihanPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MenuJenisPelatihan,
  SubMenuJenisPelatihan,
} from "../../../../../../entities/MenuJenisPelatihan";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";
import { SettingPenilaian } from "../../../../../../entities/SettingPenilaian";
import { SettingPenilaianPresenter } from "../../../../../../data/presenters/SettingPenilaianPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  menuJenisPelatihan: MenuJenisPelatihan[];
  subMenuJenisPelatihan: SubMenuJenisPelatihan[];
  formId: number;
  formDialog: string;
  formMenuJenisPelatihan: string;
  formMenuJenisPelatihanId: number;
  formSubMenuJenisPelatihan: string;
  formSubMenuJenisPelatihanId: number;
  formKode: string;
  formNamaPelatihan: string;
  formKompetensi: string;
  formJP: string;
  formKeterangan: string;
  typeMenu: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  totalJenisPelatihan: number;
  jenisPelatihan: JenisPelatihan[];
  showDialogJenisPelatihan: boolean;
  showDialogDeleteJenisPelatihan: boolean;
  subMenuJenisPelatihanIdActive: string;
  MenuJenisPelatihanIdActive: string;
  showModalSettingPenilaian: boolean;
  showDialogFormSettingPenilaian: boolean;
  showDialogDeletteSettingPenilaian: boolean;
  formJenisPelatihanId: number;
  formNilaiBawah: string;
  formNilaiAtas: string;
  formPredikat: string;
  selectJenisPelatihanId: string;
  settingPeniaian: SettingPenilaian[];
  metodePenambahan: number;
}

interface InitialState {
  state: IState;
  getMenuJenisPelatihan: Function;
  getSubMenuJenisPelatihan: Function;
  setPage: Function;
  setPageRow: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormMenuJenisPelatihan: Function;
  setFormMenuJenisPelatihanId: Function;
  setFormSubMenuJenisPelatihan: Function;
  setFormSubMenuJenisPelatihanId: Function;
  setFormKode: Function;
  setFormNamaPelatihan: Function;
  setFormKompetensi: Function;
  setFormJP: Function;
  setFormKeterangan: Function;
  setTypeMenu: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteMenuJenisPelatihan: Function;
  getJenisPelatihan: Function;
  setShowDialogJenisPelatihan: Function;
  setShowDialogDeleteJenisPelatihan: Function;
  searchJenisPelatihan: Function;
  actionFormDialogJenisPelatihan: Function;
  setUpdateJenisPelatihan: Function;
  deleteJenisPelatihan: Function;
  clearForm: Function;
  setActiveMenu: Function;
  setShowModalSettingPenilaian: Function;
  setShowDialogFormSettingPenilaian: Function;
  setShowDialogDeleteSettingPenilaian: Function;
  setFormJenisPelatihanId: Function;
  setFormNilaiBawah: Function;
  setFormNilaiAtas: Function;
  setFormPredikat: Function;
  getSettingPenilaian: Function;
  actionFormSettingPenilaian: Function;
  setUpdateSettingPenilaian: Function;
  deleteSettingPenilaian: Function;
  setFormId: Function;
  setMetodePenambahan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    menuJenisPelatihan: [],
    subMenuJenisPelatihan: [],
    formDialog: "",
    formId: 0,
    formMenuJenisPelatihan: "",
    formMenuJenisPelatihanId: 0,
    formSubMenuJenisPelatihan: "",
    formSubMenuJenisPelatihanId: 0,
    formKode: "",
    formNamaPelatihan: "",
    formKompetensi: "",
    formJP: "",
    formKeterangan: "",
    typeMenu: "menu",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    totalJenisPelatihan: 0,
    jenisPelatihan: [],
    showDialogJenisPelatihan: false,
    showDialogDeleteJenisPelatihan: false,
    subMenuJenisPelatihanIdActive: "",
    MenuJenisPelatihanIdActive: "",
    showModalSettingPenilaian: false,
    showDialogFormSettingPenilaian: false,
    showDialogDeletteSettingPenilaian: false,
    formJenisPelatihanId: 0,
    formNilaiBawah: "",
    formNilaiAtas: "",
    formPredikat: "",
    selectJenisPelatihanId: "",
    settingPeniaian: [],
    metodePenambahan: 1,
  },
  getMenuJenisPelatihan: () => {},
  getSubMenuJenisPelatihan: () => {},
  searchMenuJenisPelatihan: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormMenuJenisPelatihan: () => {},
  setFormMenuJenisPelatihanId: () => {},
  setFormSubMenuJenisPelatihan: () => {},
  setFormSubMenuJenisPelatihanId: () => {},
  setFormKode: () => {},
  setFormNamaPelatihan: () => {},
  setFormKompetensi: () => {},
  setFormJP: () => {},
  setFormKeterangan: () => {},
  setTypeMenu: () => {},
  setShowDialog: () => {},
  deleteMenuJenisPelatihan: () => {},
  setShowDialogDelete: () => {},
  getJenisPelatihan: () => {},
  setShowDialogJenisPelatihan: () => {},
  setShowDialogDeleteJenisPelatihan: () => {},
  searchJenisPelatihan: () => {},
  actionFormDialogJenisPelatihan: () => {},
  setUpdateJenisPelatihan: () => {},
  deleteJenisPelatihan: () => {},
  clearForm: () => {},
  setActiveMenu: () => {},
  setShowModalSettingPenilaian: () => {},
  setShowDialogFormSettingPenilaian: () => {},
  setShowDialogDeleteSettingPenilaian: () => {},
  setFormJenisPelatihanId: () => {},
  setFormNilaiBawah: () => {},
  setFormNilaiAtas: () => {},
  setFormPredikat: () => {},
  getSettingPenilaian: () => {},
  actionFormSettingPenilaian: () => {},
  setUpdateSettingPenilaian: () => {},
  deleteSettingPenilaian: () => {},
  setFormId: () => {},
  setMetodePenambahan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterJenisPelatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    menuJenisPelatihan: [],
    subMenuJenisPelatihan: [],
    formDialog: "",
    formId: 0,
    formMenuJenisPelatihan: "",
    formMenuJenisPelatihanId: 0,
    formSubMenuJenisPelatihan: "",
    formSubMenuJenisPelatihanId: 0,
    formKode: "",
    formNamaPelatihan: "",
    formKompetensi: "",
    formJP: "",
    formKeterangan: "",
    typeMenu: "menu",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    totalJenisPelatihan: 0,
    jenisPelatihan: [],
    showDialogJenisPelatihan: false,
    showDialogDeleteJenisPelatihan: false,
    subMenuJenisPelatihanIdActive: "",
    MenuJenisPelatihanIdActive: "",
    showModalSettingPenilaian: false,
    showDialogFormSettingPenilaian: false,
    showDialogDeletteSettingPenilaian: false,
    formJenisPelatihanId: 0,
    formNilaiBawah: "",
    formNilaiAtas: "",
    formPredikat: "",
    selectJenisPelatihanId: "",
    settingPeniaian: [],
    metodePenambahan: 1,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setMetodePenambahan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      metodePenambahan: value,
    }));
  };

  const getMenuJenisPelatihan = async () => {
    setLoading(true);

    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter,
      );
      const getAllMenuJenisPelatihan =
        await menuJenisPelatihanPresenter.getAllMenuJenisPelatihan(
          1,
          1000000,
          "",
        );

      const data = getAllMenuJenisPelatihan;

      setState((prevstate) => ({
        ...prevstate,
        menuJenisPelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getSubMenuJenisPelatihan = async () => {
    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter,
      );
      const getAllSubMenuJenisPelatihan =
        await menuJenisPelatihanPresenter.getAllSubMenuJenisPelatihan(
          1,
          1000000,
          "",
        );

      const data = getAllSubMenuJenisPelatihan;

      setState((prevstate) => ({
        ...prevstate,
        subMenuJenisPelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const setFormDialog = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: any, type: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      typeMenu: type,
    }));

    if (type == "menu") {
      setState((prevstate) => ({
        ...prevstate,
        formMenuJenisPelatihan: value.menu_jenis_pelatihan,
      }));
    } else {
      setState((prevstate) => ({
        ...prevstate,
        formMenuJenisPelatihanId: value.menu_jenis_pelatihan_id,
        formSubMenuJenisPelatihan: value.sub_menu_jenis_pelatihan,
      }));
    }

    setFormDialog("Ubah");
    setShowDialog(true);
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.typeMenu == "menu") {
      if (state.formMenuJenisPelatihan == "") {
        setFormLoading(false);
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }
    }

    if (state.typeMenu != "menu") {
      if (
        state.formMenuJenisPelatihanId == 0 ||
        state.formSubMenuJenisPelatihan == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    }

    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter,
      );
      if (state.formDialog == "Tambah") {
        if (state.typeMenu == "menu") {
          const action =
            await menuJenisPelatihanPresenter.storeMenuJenisPelatihan(
              state.formMenuJenisPelatihan,
            );
          const data = action;

          setFormLoading(false);

          if (data.data.code == 200) {
            toast.success(data.data.message, {
              style: {
                boxShadow: "0px 1px 6px #019166",
              },
            });
            setShowDialog(false);
            getMenuJenisPelatihan();
            clearForm();
          } else {
            toast.error("Ada Kesalahan Saat Tambah Data!", {
              style: {
                boxShadow: "0px 1px 6px #F86E70",
              },
            });
          }
        } else {
          const action =
            await menuJenisPelatihanPresenter.storeSubMenuJenisPelatihan(
              state.formMenuJenisPelatihanId,
              state.formSubMenuJenisPelatihan,
            );
          const data = action;

          setFormLoading(false);

          if (data.data.code == 200) {
            toast.success(data.data.message, {
              style: {
                boxShadow: "0px 1px 6px #019166",
              },
            });
            setShowDialog(false);
            getMenuJenisPelatihan();
            clearForm();
          } else {
            toast.error("Ada Kesalahan Saat Tambah Data!", {
              style: {
                boxShadow: "0px 1px 6px #F86E70",
              },
            });
          }
        }
      } else if (state.formDialog == "Ubah") {
        if (state.typeMenu == "menu") {
          const action =
            await menuJenisPelatihanPresenter.updateMenuJenisPelatihan(
              state.formId,
              state.formMenuJenisPelatihan,
            );
          const data = action;

          setFormLoading(false);

          if (data.data.code == 200) {
            toast.success(data.data.message, {
              style: {
                boxShadow: "0px 1px 6px #019166",
              },
            });
            setShowDialog(false);
            getMenuJenisPelatihan();
            clearForm();
          } else {
            toast.error("Ada Kesalahan Saat Ubah Data!", {
              style: {
                boxShadow: "0px 1px 6px #F86E70",
              },
            });
          }
        } else {
          const action =
            await menuJenisPelatihanPresenter.updateSubMenuJenisPelatihan(
              state.formId,
              state.formMenuJenisPelatihanId,
              state.formSubMenuJenisPelatihan,
            );
          const data = action;

          setFormLoading(false);

          if (data.data.code == 200) {
            toast.success(data.data.message, {
              style: {
                boxShadow: "0px 1px 6px #019166",
              },
            });
            setShowDialog(false);
            getMenuJenisPelatihan();
            clearForm();
          } else {
            toast.error("Ada Kesalahan Saat Ubah Data!");
          }
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormMenuJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formMenuJenisPelatihan: value,
    }));
  };

  const setFormMenuJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formMenuJenisPelatihanId: value,
    }));
  };

  const setFormSubMenuJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubMenuJenisPelatihan: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    if (state.formDialog == "Tambah") {
      clearForm();
    }

    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formMenuJenisPelatihan: "",
      formMenuJenisPelatihanId: 0,
      formSubMenuJenisPelatihan: "",
      formSubMenuJenisPelatihanId: 0,
      formKode: "",
      formNamaPelatihan: "",
      formKompetensi: "",
      formJP: "",
      formKeterangan: "",
      formNilaiBawah: "",
      formNilaiAtas: "",
      formPredikat: "",
    }));
  };

  const deleteMenuJenisPelatihan = async () => {
    setFormLoading(true);

    try {
      if (state.typeMenu == "menu") {
        const menuJenisPelatihanPresenter = container.resolve(
          MenuJenisPelatihanPresenter,
        );
        const action =
          await menuJenisPelatihanPresenter.deleteMenuJenisPelatihan(
            state.formId,
          );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogDelete(false);
          getMenuJenisPelatihan();
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Hapus Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else {
        const menuJenisPelatihanPresenter = container.resolve(
          MenuJenisPelatihanPresenter,
        );
        const action =
          await menuJenisPelatihanPresenter.deleteSubMenuJenisPelatihan(
            state.formId,
          );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogDelete(false);
          getMenuJenisPelatihan();
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Hapus Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error menu jenis pelatihan:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setTypeMenu = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      typeMenu: value,
    }));
  };

  const getJenisPelatihan = async (
    pagee: number,
    keyword: string,
    row: number,
    sub_menu: string,
  ) => {
    setLoading(true);

    try {
      const jenisPelatihanPresenter = container.resolve(
        JenisPelatihanPresenter,
      );
      const getAllJenisPelatihan =
        await jenisPelatihanPresenter.getAllJenisPelatihan(
          pagee,
          row,
          keyword,
          sub_menu,
        );

      const data = getAllJenisPelatihan;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const setShowDialogJenisPelatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogJenisPelatihan: value,
    }));
  };

  const setShowDialogDeleteJenisPelatihan = (value: boolean, id: number) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDeleteJenisPelatihan: value,
      formId: id,
    }));
  };

  const searchJenisPelatihan = (
    value: string,
    subMenuJenisPelatihanIdActive: string,
  ) => {
    setLoading(true);
    getJenisPelatihan(
      state.page,
      value,
      state.row,
      subMenuJenisPelatihanIdActive,
    );
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormSubMenuJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubMenuJenisPelatihanId: value,
    }));
  };

  const setFormKode = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKode: value,
    }));
  };

  const setFormNamaPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNamaPelatihan: value,
    }));
  };

  const setFormKompetensi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKompetensi: value,
    }));
  };

  const setFormJP = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJP: value,
    }));
  };

  const setFormKeterangan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKeterangan: value,
    }));
  };

  const actionFormDialogJenisPelatihan = async () => {
    setFormLoading(true);

    if (
      state.formSubMenuJenisPelatihanId == 0 ||
      state.formKode == "" ||
      state.formNamaPelatihan == "" ||
      state.formKompetensi == "" ||
      state.formJP == "" ||
      state.formKeterangan == ""
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setFormLoading(false);
      return;
    }

    try {
      const jenisPelatihanPresenter = container.resolve(
        JenisPelatihanPresenter,
      );
      if (state.formDialog == "Tambah") {
        const action = await jenisPelatihanPresenter.storeJenisPelatihan(
          state.formSubMenuJenisPelatihanId,
          state.formKode,
          state.formNamaPelatihan,
          state.formKompetensi,
          state.formJP,
          state.formKeterangan,
          state.metodePenambahan,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogJenisPelatihan(false);
          getJenisPelatihan(
            state.page,
            state.keyword,
            state.row,
            state.subMenuJenisPelatihanIdActive,
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await jenisPelatihanPresenter.updateJenisPelatihan(
          state.formId,
          state.formSubMenuJenisPelatihanId,
          state.formKode,
          state.formNamaPelatihan,
          state.formKompetensi,
          state.formJP,
          state.formKeterangan,
          state.metodePenambahan,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogJenisPelatihan(false);
          getJenisPelatihan(
            state.page,
            state.keyword,
            state.row,
            state.subMenuJenisPelatihanIdActive,
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error jenis pelatihan:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setUpdateJenisPelatihan = (value: JenisPelatihan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formSubMenuJenisPelatihanId: value.sub_menu_jenis_pelatihan_id,
      formKode: value.kode,
      formNamaPelatihan: value.nama_pelatihan,
      formKompetensi: value.kompetensi,
      formJP: value.jp,
      formKeterangan: value.keterangan,
      metodePenambahan: value.metode_penambahan,
    }));

    setFormDialog("Ubah");
    setShowDialogJenisPelatihan(true);
  };

  const deleteJenisPelatihan = async () => {
    setFormLoading(true);

    try {
      const jenisPelatihanPresenter = container.resolve(
        JenisPelatihanPresenter,
      );
      const action = await jenisPelatihanPresenter.deleteJenisPelatihan(
        state.formId,
      );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDeleteJenisPelatihan(false, 0);
        getJenisPelatihan(
          state.page,
          state.keyword,
          state.row,
          state.subMenuJenisPelatihanIdActive,
        );
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error jenis pelatihan:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setActiveMenu = (idMenu: string, idSubMenu: string) => {
    setState((prevstate) => ({
      ...prevstate,
      MenuJenisPelatihanIdActive: idMenu,
      subMenuJenisPelatihanIdActive: idSubMenu,
      jenisPelatihan: [],
    }));

    getJenisPelatihan(state.page, state.keyword, state.row, idSubMenu);
  };

  const setShowModalSettingPenilaian = (
    value: boolean,
    pelatihan_id: string,
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalSettingPenilaian: value,
      settingPeniaian: [],
      selectJenisPelatihanId: pelatihan_id,
    }));

    getSettingPenilaian(pelatihan_id);
  };

  const setShowDialogFormSettingPenilaian = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogFormSettingPenilaian: value,
    }));

    if (value) {
      setState((prevstate) => ({
        ...prevstate,
        showModalSettingPenilaian: false,
      }));
    } else {
      setState((prevstate) => ({
        ...prevstate,
        showModalSettingPenilaian: true,
      }));
    }
  };

  const setShowDialogDeleteSettingPenilaian = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDeletteSettingPenilaian: value,
    }));

    if (value) {
      setState((prevstate) => ({
        ...prevstate,
        showModalSettingPenilaian: false,
      }));
    } else {
      setState((prevstate) => ({
        ...prevstate,
        showModalSettingPenilaian: true,
      }));
    }
  };

  const setFormJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formJenisPelatihanId: value,
    }));
  };

  const setFormNilaiAtas = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNilaiAtas: value,
    }));
  };

  const setFormNilaiBawah = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNilaiBawah: value,
    }));
  };

  const setFormPredikat = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPredikat: value,
    }));
  };

  const getSettingPenilaian = async (jenis_pelatihan_id: string) => {
    setLoading(true);

    try {
      const settingPenilaianPresenter = container.resolve(
        SettingPenilaianPresenter,
      );
      const getAllSettingPenilaianByJenisPelatihanId =
        await settingPenilaianPresenter.getAllSettingPenilaian(
          1,
          1000000,
          "",
          jenis_pelatihan_id,
        );

      const data = getAllSettingPenilaianByJenisPelatihanId;

      setState((prevstate) => ({
        ...prevstate,
        settingPeniaian: data,
        formJenisPelatihanId: Number(jenis_pelatihan_id),
      }));

      setLoading(false);

      return data;
    } catch (error: any) {
      console.log("error setting penilaian:", error);
      setLoading(false);
    }
  };

  const actionFormSettingPenilaian = async () => {
    setFormLoading(true);

    if (
      state.formJenisPelatihanId == 0 ||
      state.formNilaiBawah == null ||
      state.formNilaiAtas == null ||
      state.formPredikat == null
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const settingPenilaianPresenter = container.resolve(
        SettingPenilaianPresenter,
      );
      if (state.formDialog == "Tambah") {
        const action = await settingPenilaianPresenter.storeSettingPenilaian(
          state.formJenisPelatihanId,
          state.formNilaiBawah,
          state.formNilaiAtas,
          state.formPredikat,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogFormSettingPenilaian(false);
          setShowModalSettingPenilaian(true, data.data.data.jenis_pelatihan_id);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await settingPenilaianPresenter.updateSettingPenilaian(
          state.formId,
          state.formJenisPelatihanId,
          state.formNilaiBawah,
          state.formNilaiAtas,
          state.formPredikat,
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogFormSettingPenilaian(false);
          setShowModalSettingPenilaian(true, data.data.data.jenis_pelatihan_id);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error setting penilaian :", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setUpdateSettingPenilaian = (value: SettingPenilaian) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formJenisPelatihanId: Number(value.jenis_pelatihan_id),
      formNilaiBawah: value.nilai_bawah,
      formNilaiAtas: value.nilai_atas,
      formPredikat: value.predikat,
    }));

    setFormDialog("Ubah");
    setShowDialogFormSettingPenilaian(true);
  };

  const deleteSettingPenilaian = async () => {
    setFormLoading(true);

    try {
      const settingPenilaianPresenter = container.resolve(
        SettingPenilaianPresenter,
      );
      const action = await settingPenilaianPresenter.deleteSettingPenilaian(
        state.formId,
      );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDeleteSettingPenilaian(false);
        setShowModalSettingPenilaian(true, state.selectJenisPelatihanId);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error jenis pelatihan:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value,
    }));
  };

  return (
    <MasterJenisPelatihanProvider
      value={{
        state,
        getMenuJenisPelatihan,
        getSubMenuJenisPelatihan,
        setPage,
        setPageRow,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormMenuJenisPelatihan,
        setFormMenuJenisPelatihanId,
        setFormSubMenuJenisPelatihan,
        setFormSubMenuJenisPelatihanId,
        setFormKode,
        setFormNamaPelatihan,
        setFormKompetensi,
        setFormJP,
        setFormKeterangan,
        setTypeMenu,
        setShowDialog,
        deleteMenuJenisPelatihan,
        setShowDialogDelete,
        getJenisPelatihan,
        setShowDialogJenisPelatihan,
        setShowDialogDeleteJenisPelatihan,
        searchJenisPelatihan,
        actionFormDialogJenisPelatihan,
        setUpdateJenisPelatihan,
        deleteJenisPelatihan,
        clearForm,
        setActiveMenu,
        setShowModalSettingPenilaian,
        setShowDialogFormSettingPenilaian,
        setShowDialogDeleteSettingPenilaian,
        setFormJenisPelatihanId,
        setFormNilaiBawah,
        setFormNilaiAtas,
        setFormPredikat,
        getSettingPenilaian,
        actionFormSettingPenilaian,
        setUpdateSettingPenilaian,
        deleteSettingPenilaian,
        setFormId,
        setMetodePenambahan,
      }}
    >
      {children}
    </MasterJenisPelatihanProvider>
  );
};

export const useMasterJenisPelatihanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterJenisPelatihanContext,
  Provider,
};
