import React from 'react'
import JoyrideComponent from '../global/Joyride';
import { Box } from '@chakra-ui/react';
import { Placement } from 'react-joyride';


/**
 * Provides guided tour steps for the knowledge center page.
 * The steps are:
 * 1. Search Pengetahuan: Temukan pengetahuan baru yang ingin kamu ketahui disini
 * 2. Lihat detail dan informasi pengetahuan, disini
 * @returns {JSX.Element} The GuidePengetahuan component.
 * @author Bagas Prasetyadi
 */

const GuidePengetahuan = () => {
    const steps = [
        {
          content: (
            <p>
              Search Pengetahuan: Temukan pengetahuan baru yang ingin kamu ketahui
              disini
            </p>
          ),
          placement: "bottom" as Placement,
          target: "#search-pengetahuan",
          disableBeacon: true,
        },
        {
          content: <p>Lihat detail dan informasi pengetahuan, disini</p>,
          placement: "bottom" as Placement,
          target: ".pengetahuan-item:nth-child(1)",
          disableBeacon: true,
        },
      ];
    
      return (
        <Box mt={{ base: 4, md: 0 }}>
          <JoyrideComponent steps={steps} />
        </Box>
      );
}

export default GuidePengetahuan