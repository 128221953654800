/* eslint-disable eqeqeq */
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import React from "react";
import { toast } from "react-toastify";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../../../data/presenters/PelatihanPresenter";
import { PelatihanSayaPresenter } from "../../../../../../../data/presenters/PelatihanSayaPresenter";
import axiosInstance from "../../../../../../api/axiosInstance";

interface IState {
  isLoading: boolean;
  pelatihan: Pelatihan | null;
  showDialogDelete: boolean;
  showDialogDeletePeserta: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
  isLoadingDeletePeserta: boolean;
  page: number;
  keyword: string;
  row: number;
  jumlahPage: number;
  pelatihanSaya: PelatihanSaya[];
  pelatihan_id: string;
  selectedPelatihanPesertaId: number;
  showModalSertifikat: boolean;
  path_sertifikat: string;
  isLoadingDownloadPeserta: boolean;
  loadingRowIdCalculatePelatihan: number;
  isLoadingGenerateExcel: boolean;
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  setShowDialogDelete: Function;
  setShowDialogDeletePeserta: Function;
  setSelectedId: Function;
  deletePelatihan: Function;
  deletePesertaPelatihan: Function;
  getDaftarPeserta: Function;
  search: Function;
  setPage: Function;
  setPageRow: Function;
  setPelatihanPesertaId: Function;
  setShowSertifikat: Function;
  setPathSertifikat: Function;
  downloadPeserta: Function;
  calculatePelatihanValue: Function;
  genereateExcel: Function;
}

const initialState = {
  state: {
    isLoading: true,
    pelatihan: null,
    showDialogDelete: false,
    showDialogDeletePeserta: false,
    selectedId: 0,
    isLoadingDelete: false,
    isLoadingDeletePeserta: false,
    page: 1,
    keyword: "",
    row: 10,
    jumlahPage: 0,
    pelatihanSaya: [],
    pelatihan_id: "",
    selectedPelatihanPesertaId: 0,
    showModalSertifikat: false,
    path_sertifikat: "",
    isLoadingDownloadPeserta: false,
    loadingRowIdCalculatePelatihan: 0,
    isLoadingGenerateExcel: false,
  },
  getDetailPelatihan: () => {},
  setShowDialogDelete: () => {},
  setShowDialogDeletePeserta: () => {},
  setSelectedId: () => {},
  deletePesertaPelatihan: () => {},
  deletePelatihan: () => {},
  getDaftarPeserta: () => {},
  search: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setPelatihanPesertaId: () => {},
  setShowSertifikat: () => {},
  setPathSertifikat: () => {},
  downloadPeserta: () => {},
  calculatePelatihanValue: () => {},
  genereateExcel: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const chakraToast = useToast();
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    isLoadingDelete: false,
    isLoadingDeletePeserta: false,
    pelatihan: null,
    showDialogDelete: false,
    showDialogDeletePeserta: false,
    selectedId: 0,
    page: 1,
    keyword: "",
    row: 10,
    jumlahPage: 0,
    pelatihanSaya: [],
    pelatihan_id: "",
    selectedPelatihanPesertaId: 0,
    showModalSertifikat: false,
    path_sertifikat: "",
    isLoadingDownloadPeserta: false,
    loadingRowIdCalculatePelatihan: 0,
    isLoadingGenerateExcel: false,
  });

  const calculatePelatihanValue = async (id: number) => {
    setLoadingRowIdCalculatePelatihan(id);
    try {
      const response = await axiosInstance.post(
        `pelatihan_saya/${id}/hitung_ulang_nilai_akhir`
      );
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        getDaftarPeserta(1, "", 10, state.pelatihan_id);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoadingRowIdCalculatePelatihan(0);
    }
  };

  const setLoadingRowIdCalculatePelatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      loadingRowIdCalculatePelatihan: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const setLoadingDeletePeserta = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDeletePeserta: value,
    }));
  };

  const setLoadingDownloadPeserta = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDownloadPeserta: value,
    }));
  };

  const setLoadingGenerateExcel = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingGenerateExcel: value,
    }));
  };

  const setPelatihanPesertaId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedPelatihanPesertaId: value,
    }));
  };

  const setShowSertifikat = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalSertifikat: value,
    }));
  };

  const setPathSertifikat = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      path_sertifikat: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setShowDialogDeletePeserta = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDeletePeserta: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const deletePelatihan = async () => {
    setLoadingDelete(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const deletePelatihan = await pelatihanPresenter.deletePelatihan(
        state.selectedId,
      );

      const data = deletePelatihan;

      if (data.data.code == 200) {
        toast.success("Berhasil Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_pelatihan`);
        window.location.reload();
      } else {
        toast.error("Gagal Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setLoadingDelete(false);
    }
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getDaftarPeserta = async (
    pagee: number,
    keyword: string,
    row: number,
    pelatihan_id: string,
  ) => {
    setLoading(true);

    setState((prevstate) => ({
      ...prevstate,
      pelatihan_id: pelatihan_id,
    }));

    try {
      const pelatihanSayaPresenter = container.resolve(PelatihanSayaPresenter);
      const getAllDaftarPeserta =
        await pelatihanSayaPresenter.getAllPelatihanSaya(
          pagee,
          row,
          keyword,
          pelatihan_id,
        );

      const data = getAllDaftarPeserta;

      if (data.length > 0) {
        setJumlahPage(data[0].jumlahData / row);
      }

      setState((prevstate) => ({
        ...prevstate,
        pelatihanSaya: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error daftar peserta:", error);
      setLoading(false);
    }
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const search = (value: string, pelatihan_id: string) => {
    setLoading(true);
    getDaftarPeserta(state.page, value, state.row, pelatihan_id);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const deletePesertaPelatihan = async () => {
    setLoadingDeletePeserta(true);

    try {
      const pelatihanSayaPresenter = container.resolve(PelatihanSayaPresenter);

      await pelatihanSayaPresenter.deletePelatihanSaya(
        state.selectedPelatihanPesertaId,
      );

      toast.success("Berhasil Menghapus Peserta Pelatihan", {
        style: {
          boxShadow: "0px 1px 6px #019166",
        },
      });
      window.location.reload();
    } catch (error: any) {
      console.log("error when delete peserta pelatihan :", error);
      toast.error("Ada Kesalahan Saat Menghapus Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingDeletePeserta(false);
    }
  };

  const downloadPeserta = async () => {
    setLoadingDownloadPeserta(true);

    axios(
      `${process.env.REACT_APP_BE_PROD_BASE_URL}file/excel?pelatihan_id=${state.pelatihan_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("tokenAuth")}`,
        },
      },
    )
      .then(({ data }) => {
        window.open(data?.data?.download_url, "_blank");
        setLoadingDownloadPeserta(false);
      })
      .catch((error) => {
        setLoadingDownloadPeserta(false);
        chakraToast({
          title: `Terjadi error ketika mendownload excel`,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const genereateExcel = async () => {
    setLoadingGenerateExcel(true);

    axios(
      `${process.env.REACT_APP_BE_PROD_BASE_URL}export/peserta?pelatihan_id=${state.pelatihan_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("tokenAuth")}`,
        },
      }
    )
      .then(({ data }) => {
        chakraToast({
          title: data?.data ?? "Berhasil Mengenerate Excel",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        setLoadingGenerateExcel(false);
      })
      .catch((error) => {
        setLoadingGenerateExcel(false);
        chakraToast({
          title: `Terjadi error ketika mengenerate excel`,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  };

  return (
    <ManajemenPelatihanDetailProvider
      value={{
        state,
        getDetailPelatihan,
        setShowDialogDelete,
        setShowDialogDeletePeserta,
        setSelectedId,
        deletePelatihan,
        getDaftarPeserta,
        search,
        setPage,
        setPageRow,
        deletePesertaPelatihan,
        setPelatihanPesertaId,
        setShowSertifikat,
        setPathSertifikat,
        downloadPeserta,
        calculatePelatihanValue,
        genereateExcel,
      }}
    >
      {children}
    </ManajemenPelatihanDetailProvider>
  );
};

export const useDetailPelatihanDaftarPesertaContext = () =>
  React.useContext(Context);

export default {
  useDetailPelatihanDaftarPesertaContext,
  Provider,
};
